/*global Modernizr, EdqResourceVersion */

/* Utility functions
******************************************************/

var Utilities = {

    /* Breakpoint */
    // Get our screen breakpoints in JS
    breakpoint: {
        xs: 480,
        sm: 768,
        md: 992,
        lg: 1200
    },

    /* Global Colors */
    colors: {
        dark_blue:  '#26478d',
        light_blue: '#406eb3',
        violet:     '#632678',
        purple:     '#982881',
        pink:       '#ba2f7d',
        red:        '#bb0048',
        orange:     '#e2a235',
        yellow:     '#fde723',
        green:      '#c8c922',
        sapphire:   '#3d87af',
        dark_grey:  '#575757',
        medium_grey:'#dddddd',
        light_grey: '#f4f4f4',
        white:      '#ffffff'
    },

    /* Escape HTML */
    // Return an HTML-safe version of a string
    escapeHtml: function (value) {
        var div = document.createElement("div");
        div.appendChild(document.createTextNode(value));
        return div.innerHTML;
    },

    /* Unescape HTML */
    // Decodes an html encoded string.
    unescapeHtml: function (value) {
        var div = document.createElement("div");
        div.innerHTML = value;
        return div.textContent;
    },

    /* HTML escape serialized form data */
    // Return an HTML-safe version of a serialized form data string 
    htmlEscapeFormData: function (serializedFormData) {
        var normalized = [],
            parts,
            rplus = /\+/g,
            data = serializedFormData.split("&"),
			length = data.length;

        for (var i = 0; i < length; i++) {
            parts = data[i].split("=");
            normalized.push({
                name: Utilities.escapeHtml(decodeURIComponent(parts[0].replace(rplus, "%20"))),
                value: Utilities.escapeHtml(decodeURIComponent(parts[1].replace(rplus, "%20")))
            });
        }

        return $.param(normalized);
    },

    /* Is null or empty */
    // Returns true if a variable is undefined, null, whitespace or empty; else returns false
    isNullOrEmpty: function (thing) {

        // Check the simple things
        if (typeof thing === "undefined" || thing === null) {
            return true;
        }

            // Booleans and functions are never judged to be empty
        else if (typeof thing === "number" || typeof thing === "boolean" || typeof thing === "function") {
            return false;
        }

            // Check for empty strings after removing whitespace
        else if (typeof thing === "string") {
            return thing.replace(/\s+/g, "") === "";
        }

            // Check for arrays with zero length
        else if (thing.constructor === Array && thing.length < 1) {
            return true;
        }

            // Check for objects with no properties
        else if (Object.prototype.toString.call(thing).slice(8, -1) === "Object") {
            var props = 0;
            for (var prop in thing) {
                if (thing.hasOwnProperty(prop)) {
                    props++;
                }
            }
            if (props < 1) {
                return true;
            }
        }

        // If we've got this far, the thing is not null or empty
        return false;
    },

    /* Prints a number with commas (thousands separator) */
    // TODO: in the future modify this for foreign number formats (I'm looking at you, France)
    /* Usage examples:
        Utilities.numberWithCommas(89898989); // 89,898,989
        Utilities.numberWithCommas(89898989, { decimalPlaces: 3 }); // 89,898,989.000
        Utilities.numberWithCommas(89898989.345678, { decimalPlaces: 2 }); // 89,898,989.34
    */
    numberWithCommas: function (x, options) {

        // Allow only numbers
        if (typeof x !== "number") {
            return x;
        }

        // Place a comma between every set of three digits, except after a decimal point
        var parts = x.toString().split(".");
        var remainder = (typeof parts[1] !== "undefined") ? "." + parts[1] : "";
        var number = parts[0];
        var numberWithCommas = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + remainder;

        // Enforce the requested number of decimal places
        if (!Utilities.isNullOrEmpty(options) && typeof options.decimalPlaces === "number") {
            if (numberWithCommas.indexOf(".") > -1) {
                var decParts = numberWithCommas.toString().split(".");
                var decRemainder = decParts[1].substring(0, options.decimalPlaces);
                numberWithCommas = decParts[0];
                // If a remainder exists, append it
                if (!Utilities.isNullOrEmpty(decRemainder)) {
                    // Pad with extra zeroes if needed
                    if (decRemainder.length < options.decimalPlaces) {
                        decRemainder += Array((options.decimalPlaces - decRemainder.length) + 1).join("0");
                    }
                    numberWithCommas += "." + decRemainder;
                }
            }
                // Pad out with zeroes after decimal point
            else if (options.decimalPlaces > 0) {
                numberWithCommas += "." + Array(options.decimalPlaces + 1).join("0");
            }
        }

        return numberWithCommas;
    },

    /* Returns a "pretty" number that converts, say, 8,100,000 to 8.1 million */
    // This is currently very cultural specific.
    prettyNumber: function (x) {
        // Remove comma separator
        var cleanNumber = x.replace(/,/g, '');
        var digits = cleanNumber.length;

        if (digits > 6) {
            var shortNumber = parseInt(cleanNumber, 10) / 1000000;
            return shortNumber + ' million';
        } else {
            return x;
        }
    },

    /* Returns a string with all instances of another string replaced */
    replaceAll: function (str, search, replacement) {
        if (!Utilities.isNullOrEmpty(str) && !Utilities.isNullOrEmpty(search)) {
            // Use an empty string if replacement is empty (i.e. just strip out the 'search' parameter)
            replacement = (Utilities.isNullOrEmpty(replacement)) ? "" : replacement;
            return str.replace(new RegExp(search, 'g'), replacement);
        } else {
            return str;
        }
    },

    /* Returns a "pretty" date from a Date object, optionally including the time */
    /* Usage examples:
        Utilities.prettyDate(date);       // Monday October 19, 2015
        Utilities.prettyDate(date, true); // 17:00 on Monday October 19, 2015
    */
    // This is currently very cultural specific.
    prettyDate: function (date, includeTime) {

        // Accept only dates
        if (typeof date.toUTCString !== "function") {
            return date;
        }

        var days = {
            0: "Sunday",
            1: "Monday",
            2: "Tuesday",
            3: "Wednesday",
            4: "Thursday",
            5: "Friday",
            6: "Saturday"
        };
        var months = {
            0: "January",
            1: "February",
            2: "March",
            3: "April",
            4: "May",
            5: "June",
            6: "July",
            7: "August",
            8: "September",
            9: "October",
            10: "November",
            11: "December"
        };

        var dayInt = date.getDate();
        var day = days[date.getDay()];
        var month = months[date.getMonth()];
        var year = date.getFullYear();

        var dateString = day + " " + month + " " + dayInt + ", " + year;

        if (includeTime) {
            var hours = (date.getHours().toString().length < 2) ? "0" + date.getHours() : date.getHours();
            var minutes = (date.getMinutes().toString().length < 2) ? "0" + date.getMinutes() : date.getMinutes();
            dateString = hours + ":" + minutes + " on " + dateString;
        }

        return dateString;

    },

    /* Returns a "Copy" button for insertion into the DOM */
    // elementToCopy is the HTML element (eg an input) which contains the data to copy
    // buttonId is the string to use as the button's ID
    // buttonClasses is an optional set of additional classes for the button
    // isSmall is an optional boolean indicating whether the button should be smaller than the standard button size
    getCopyButton: function (elementToCopy, buttonId, buttonClasses, isSmall) {

        var isSupported = function () {

            // Support isn't reported correctly on Safari, so UA sniffing is used to discount Safari on desktop or iDevices
            // (it's likely to be a while before they support this)
            var isSafari = (navigator.userAgent.indexOf("Safari") > -1 || navigator.userAgent.indexOf("AppleWebKit") > -1) &&
                            navigator.userAgent.indexOf("Chrome") < 0;
            if (isSafari) {
                return false;
            }

            // Checking for support in advance triggers a permissions pop up in supported versions of IE
            // We want to avoid this at least until the button is clicked, so bypass this check in IE > 8
            var isIEgt8 = !document.attachEvent &&
                          (navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1);
            if (isIEgt8) {
                return true;
            }

            // Other supported browsers return a boolean in response to the following query
            try {
                if (typeof document.execCommand("copy") === "boolean") {
                    return true;
                }
            }
            catch (err) {
                return false;
            }

            // If we've got this far, this browser is not supported
            return false;
        };

        // Return false if copy functionality is not supported
        if (!isSupported()) {
            return false;
        }

        // Return null if required arguments are missing
        if (Utilities.isNullOrEmpty(elementToCopy) || Utilities.isNullOrEmpty(buttonId)) {
            return null;
        }

        // Return null if the HTML element to copy from does not exist
        if (elementToCopy.length < 1) {
            return null;
        }

        // Set isSmall to false if anything other than a bool was passed in
        if (Utilities.isNullOrEmpty(isSmall) || typeof isSmall !== "boolean") {
            isSmall = false;
        }

        // If a JQuery object has been passed in as the HTML element, break it out
        if (elementToCopy instanceof jQuery) {
            elementToCopy = elementToCopy[0];
        }

        // Stateful components of the copy button
        var buttonHtml = "<span class='icon-pencil copy-ready'>&nbsp;Copy&nbsp;</span>" +
                         "<span class='icon-ok copy-done'>&nbsp;Copied&nbsp;</span>" +
                         "<span class='icon-cancel copy-failed'>&nbsp;Failed&nbsp;</span>";

        var buttonClassString = "btn btn-copy-to-clipboard btn-inline",
            smallButtonClass = "btn-xs";
        buttonClassString += (!Utilities.isNullOrEmpty(buttonClasses)) ? " " + buttonClasses : "";
        buttonClassString += (isSmall) ? " " + smallButtonClass : "";

        // Create the button
        var button = document.createElement("button");
        button.setAttribute("type", "button");
        button.setAttribute("id", buttonId);
        button.setAttribute("class", buttonClassString);
        button.innerHTML = buttonHtml;

        // Attach a click handler to the button to copy the text and mark as copied
        button.addEventListener("click", function () {
            elementToCopy.focus();
            elementToCopy.setSelectionRange(0, 9999);
            // TODO make this part work better. Currently always returns true in IE, 
            // even if user blocked the action via the permission pop up
            var copySuccessful = document.execCommand("copy");
            button.focus();
            button.classList.add(copySuccessful ? "copy-done" : "copy-failed");
        });

        // Attach a change listener to the elementToCopy to reset the button content
        elementToCopy.addEventListener("input", function () {
            button.setAttribute("class", buttonClassString);
        });

        // Attach a change listener to the elementToCopy to reset the button content
        elementToCopy.addEventListener("input", function () {
            button.setAttribute("class", buttonClassString);
        });

        return button;
    },

    /* Returns the currency symbol for a given ISO 4217 code */
    // Maybe default should be dollar...
    getCurrencySymbol: function (code) {
        var symbol = "";
        switch (code) {
            case "USD":
                symbol = "$";
                break;
            case "GBP":
                symbol = "£";
                break;
            default:
                break;
        }
        return symbol;
    },

    /* Geolocation */
    // Get the current user's location, and run a callback function utilising this information
    // The geolocation is only requested once; subsequent requests will use a cached copy of the information
    // Geolocation is always returned as null when running locally
    /* Usage example:
        Utilities.geolocation.runAfter(function () {
            console.log(Utilities.geolocation.current);
        });
    */
    geolocation: {
        current: null,
        requestedBefore: false,
        setDefault: function () {
            // Default to return when testing locally
            if (window.location.hostname === "localhost") {
                Utilities.geolocation.current = "GB";
            }
                // Default to return based on site URL (basic guesses for Global/UK etc)
            else {
                switch (window.location.pathname.toLowerCase().split("/")[1]) {
                    case "uk": Utilities.geolocation.current = "GB"; break;
                    case "au": Utilities.geolocation.current = "AU"; break;
                    default: Utilities.geolocation.current = "US";
                }
            }
        },
        runAfter: function (callback) {
            if (!Utilities.geolocation.requestedBefore) {
                $.ajax({
                    type: "POST",
                    url: "/ajaxutilities/getgeolocation",
                    dataType: "json",
                    timeout: 2000,
                    success: function (result) {
                        if (result.countryCode !== null) {
                            Utilities.geolocation.current = result.countryCode; // Hardcode a value here for local testing
                        }
                        else {
                            Utilities.geolocation.setDefault();
                        }
                    },
                    error: function () {
                        Utilities.geolocation.setDefault();
                    },
                    complete: function () {
                        callback(Utilities.geolocation.current);
                        Utilities.geolocation.requestedBefore = true;
                    }
                });
            }
            else {
                callback(Utilities.geolocation.current);
            }
        }
    },

    /* Lazy image */
    // Returns raw HTML for a lazy-load image
    // imageSrc is the filepath to use, eg EdqResourceVersion + "/images/flags/en.svg", which you might construct before sending to this function
    // altText is used as both the alt and title attributes
    // attrs is an object containing one or more named attributes and their values as strings, eg {"class":"inline-flag small", "data-thing": "value"}
    /* Usage example:
        var iso2 = "en",
            src = EdqResourceVersion + "/images/flags/" + iso2 + ".svg",
            name = "United Kingdom",
            imgHtml = Utilities.lazyImage(src, name, { "class": "inline-flag", "data-country": iso2 });
        $(".image-element").html(imgHtml);
    */
    lazyImage: function (imageSrc, altText, attrs) {

        if (typeof imageSrc !== "undefined" || typeof altText !== "undefined") {

            var classString = "", attrString = "",
                lazyImgHtml;

            // Construct a string containing additional classes, or the supplied attribute names and values
            if (typeof attrs !== "undefined") {
                $.each(attrs, function (key, value) {
                    if (key.toLowerCase() === "class") {
                        classString += (" " + value);
                    }
                    else {
                        attrString += (key + "='" + value + "' ");
                    }
                });
            }

            // Construct an HTML string representing the lazy-load image
            lazyImgHtml = "<img " +
                            "loading='lazy' class='" + classString + "' " +
                            attrString +
                            "alt='" + altText + "' title='" + altText + "' " +
                            "' src='" + imageSrc + "'" +
                          " />";

            return lazyImgHtml;
        }
        else {
            return "<!-- Error: when calling Utilities.lazyImage, the imageSrc and altText arguments are both required. -->";
        }
    },

    /* Max */
    // Returns an int which equals the highest number in an array
    max: function (array) {
        return Math.max.apply(Math, array);
    },

    /* Parse URL Parameters */
    // Returns the value of a requested URL parameter, based on the requested key and optionally from a URL passed to the function
    parseUrlParams: function (key, url) {
        var value = null,
            keys = [],
            searchUrl = location.search.substr(1); // default search string to current URL of page
        
        if (typeof url !== "undefined") { searchUrl = url; }

        searchUrl.split("&")
            .forEach(function (item) {
                keys = item.split("=");
                if (keys[0] === key) {
                    value = decodeURIComponent(keys[1]);
                }
            });
        return value;
    },

    /* Sort data */
    // Sort an array of objects by a given property name
    // Usage: array.sort(Utilities.sortData("propertyName"));
    sortData: function (property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        };
    },

    /* Track event */
    trackEvent: function (eventObject) {
        // Pushes a new object to the dataLayer allowing the event to be logged in Google Analytics
        if (typeof (dataLayer) !== "undefined") {
            dataLayer.push(eventObject);
        }
    },

    /* mmm, cookies */
    // Basic create cookie function
    setCookie: function (name, value, days) {
        var expiry = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expiry = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expiry + "; path=/";
    },

    // Create cookie with custom path function
    setCookieWithPath: function (name, value, days, path) {
        var expiry = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expiry = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + value + expiry + "; path=" + path;
    },

    // Basic read cookie function
    getCookie: function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1, c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }
        return null;
    },

    /* Storage */
    // Local and Session storage functions. Done here to provide feature detection.
    // The native function is retrieved if supported.
    // TODO: decide whether to polyfill if not supported.
    localStorage: {
        getItem: function (key) {
            return Utilities.storageOperations("getItem", "local")(key);
        },
        setItem: function (key, value) {
            return Utilities.storageOperations("setItem", "local")(key, value);
        },
        removeItem: function (key) {
            return Utilities.storageOperations("removeItem", "local")(key);
        },
        clear: function () {
            return Utilities.storageOperations("clear", "local")();
        }
    },

    sessionStorage: {
        getItem: function (key) {
            return Utilities.storageOperations("getItem", "session")(key);
        },
        setItem: function (key, value) {
            return Utilities.storageOperations("setItem", "session")(key, value);
        },
        removeItem: function (key) {
            return Utilities.storageOperations("removeItem", "session")(key);
        },
        clear: function () {
            return Utilities.storageOperations("clear", "session")();
        }
    },

    // Choose between local and session storage functions and return the correct (native) one
    storageOperations: function (fn, scope) {

        var storageScope = scope + "Storage";

        // Perhaps don't rely on Modernizr... we have it for this reason, but might not always.
        function isSupported() {
            if (Modernizr && Modernizr[storageScope.toLowerCase()]) {
                return true;
            }
            return false;
        }

        var returnFunction;
        if (!isSupported()) {
            returnFunction = function () {
                return $.noop();
            };
        }
        else {
            switch (fn) {
                case "getItem":
                    returnFunction = function (key) {
                        return window[storageScope].getItem(key);
                    };
                    break;
                case "setItem":
                    returnFunction = function (key, value) {
                        window[storageScope].setItem(key, value);
                    };
                    break;
                case "removeItem":
                    returnFunction = function (key) {
                        window[storageScope].removeItem(key);
                    };
                    break;
                case "clear":
                    returnFunction = function () {
                        window[storageScope].clear();
                    };
                    break;
            }
        }

        return returnFunction;
    },

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    debounce: function (func, wait, immediate) {

        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) {
                    func.apply(context, args);
                }
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) {
                func.apply(context, args);
            }
        };
    }
};

/* JQuery plugins
******************************************************/

/* In view */
// Trigger a callback function when an element comes into view
// In IE8, fires on ready instead due to a dependency of JQuery 1.4 or higher
$.fn.inView = function (callback) {
    if ($("html").hasClass("lt-ie9")) {
        $(function () {
            callback();
        });
    }
    else {
        this.one("inview", function (event, isInView) {
            if (isInView) {
                callback(event);
            }
        });
    }
};

/* Toggle loaders */
// Toggle all loaders within a container on or off
$.fn.toggleLoaders = function (state) {
    var display = (state) ? ((state === "fadeIn") ? "fadeIn" : "fadeOut") : "fadeToggle";
    this.find(".loader")[display]();

    var $modalLoader = this.find(".loader-modal");
    var modalLoaderDisplay = display === "fadeOut" ? "removeClass" : display === "fadeIn" ? "addClass" : "toggleClass";
    $modalLoader.parent()[modalLoaderDisplay]("text-transparent");
};
$(function(){

/* BREADCRUMBS */

$(window).on("load resize", function () {
    var width = 0;
    var $trail = $(".breadcrumb-wrapper .container");
    var $breadCrumb = $trail.find(".breadcrumb li");
    $breadCrumb.each(function () {
        width += $(this).width();
    });
    $trail.animate({
        scrollLeft: width
    }, 0);
    return false;
});

/* COOKIES */

// Set a cookie when an alert is dismissed (if expressed on the data attrs)
$(document).on('click', '[data-dismiss="alert"]', function () {
    var $this = $(this),
        name = $this.attr('data-dismiss-cookie-name'),
        value = $this.attr('data-dismiss-cookie-value'),
        expiry = $this.attr('data-dismiss-cookie-expiry');
    if (name && value && expiry) {
        Utilities.setCookie(name, value, expiry);
    }
});
/* global GetElqContentPersonalizationValue, GetElqCustomerGUID */

$(function() {
    // Listen for when the postEloquaDataLookup event has been triggered
    $("body").on("postEloquaDataLookup", function(){
        
        // Check that we haven't already pushed this to the dataLayer
        var alreadyPushed = Utilities.sessionStorage.getItem("pushedCompanyToDataLayer");
        if(!alreadyPushed && typeof GetElqCustomerGUID === "function") {

            // Create a custom event
            var obj = {
                'event': 'EloquaLookup',
                'customerGuid': GetElqCustomerGUID(),
                'companyName': GetElqContentPersonalizationValue(EdqXforms.eloqua.eloquaFieldNames.company),
            };

            // Push event to the dataLayer
            Utilities.trackEvent(obj);

            // Store in sessionStorage that we have pushed to the dataLayer
            Utilities.sessionStorage.setItem("pushedCompanyToDataLayer", "true");
        }
    });
});

/* Editorial behaviour */

// slideToggle a div when an anchor is clicked (if the target is in a .slide-toggle container)
$("a[href^='#']").on("click", function(e){
    var href = $(this).attr("href");

    // Ensure that the href is present and contains at least one other character after the #
    if (href && href.length > 1) {
      var $target = $(href);
      if($target.parent().hasClass("slide-toggle")){
          $target.parent().slideToggle();
          e.preventDefault();
      }
    }
});
/*global require */
/*global FontFaceObserver */

require(['libraries/fontfaceobserver'], function () {
    (function () {
        if (document.documentElement.className.indexOf("fonts-loaded") > -1) {
            return;
        }
        var one = new FontFaceObserver("Roboto", {
            weight: 100
        });
        var one_italic = new FontFaceObserver("Roboto", {
            weight: 100,
            style: 'italic'
        });
        var three = new FontFaceObserver("Roboto", {
            weight: 300
        });
        var three_italic = new FontFaceObserver("Roboto", {
            weight: 300,
            style: 'italic'
        });
        var four = new FontFaceObserver("Roboto", {
            weight: 400
        });
        var four_italic = new FontFaceObserver("Roboto", {
            weight: 400,
            style: 'italic'
        });
        var five = new FontFaceObserver("Roboto", {
            weight: 500
        });
        var five_italic = new FontFaceObserver("Roboto", {
            weight: 500,
            style: 'italic'
        });
        Promise
            .all([one.load(), one_italic.load(), three.load(), three_italic.load(), four.load(), four_italic.load(), five.load(), five_italic.load()])
            .then(function () {
                document.documentElement.className += " fonts-loaded";
            });
    }());
});

/* MENUS */
$(function() {

    var $menu = $('#docs-toc-sidebar');
    var $menu_items = $menu.find('.list-group-item a');

    setHoverMenuActivation();

    $menu_items.each(function (i, linkElement) {
        $(linkElement).on("click", function (e) {
            var anchorId = $(this).attr("href");
            var $target = getAnchorFromHref(anchorId);
            if ($target) {
                e.preventDefault();
                // Calculate distance from top of window (deducting both menu heights so it's not obscured)
                var scrollTopPos = $target.offset().top - mainMenuAndSubMenuHeight();
                // But if the sub nav menu isn't fixed yet (i.e. we're at the top of page), 
                // then deduct the navbar height to account for this.
                // Because it WILL be fixed by the time we've scrolled there!
                if (!isSubNavMenuFixed()) {
                    scrollTopPos -= $subNavMenu.outerHeight();
                }
                $('html, body').animate({
                    scrollTop: scrollTopPos
                }, 400);
            }
        });
    });

    function autocollapse() {
        var $navbar = $('#local-navigation').length ? $('#local-navigation') : $('#modern-local-navigation'),
            $navbarToggleBtn = $('.navbar-toggle', $navbar),
            $navbarList = $('ul.navbar-nav'),
            $navDropDown = $('.local-nav-dropdown', $navbar),
            $navTopFour = $('.navbar-top-four'),
            $navDropDownTopFour = $navTopFour.find('.local-nav-dropdown'),
            $mainNav = $('#main-navbar');

        $navbar.removeClass('collapsed'); // set standard view
        $navbarList.removeClass('is-collapsed'); // set standard view
        $navDropDown.removeClass('m-dropdown'); // set standard view

        if ($navTopFour && $navTopFour.find(' > li').length === 0 && $navbarList) {
            // Temporary hack to include 6 menu items permanently rather than 4
            var topFourItems = $navbarList.children('li').slice(0,6);
            topFourItems.clone().appendTo($navTopFour);
        }

        // Check if navbar is open by looking at the height
        if ($navbar.innerHeight() > 175) {
            $navbar.addClass('collapsed'); // force collapse mode
        }
        if ($navbarToggleBtn.is(':visible')) {
            $navDropDown.addClass('m-dropdown');

            if ($navTopFour.is(":hidden")) {
                $navbarList.addClass('is-collapsed'); // navbar collapsed
            }
            else {
                $navDropDownTopFour.removeClass('m-dropdown');
                $mainNav.addClass('is-collapsed');
            }
        }
    }

    function stickyHeader(navbar) {
        var minScrollAmount = navbar.outerHeight();

        if ($(window).scrollTop() > minScrollAmount) {
            navbar.addClass("navbar-fixed-top");
            navbar.addClass("show-abbreviated-logo");
            navbar.removeClass("hide-abbreviated-logo");
            $('body').addClass("fixed-top-padding");
        } else {

            if(navbar.hasClass("show-abbreviated-logo")) {
                navbar.addClass("hide-abbreviated-logo");
                navbar.removeClass("show-abbreviated-logo");
            }

            navbar.removeClass("navbar-fixed-top");
            $('body').removeClass("fixed-top-padding");
        }
    }

    $('#local-navigation .local-nav-dropdown').click(function(e) {
        $(this).toggleClass('open');
        $(this).toggleClass('flip-up');
        $(this).find(".dropdown-menu").toggleClass('open');
    });

    $('#modern-local-navigation .dropdown-toggle').click(function (e) {
        if (!($(e.target).is('.nav-text'))) { //if click on glyphicon or anywhere except the text: open drop-down
            e.preventDefault();
            //close all other menus
            $('.hover-dropdown').not($(this).closest('.hover-dropdown')).removeClass("open");
            //open clicked menu
            $(this).closest('.hover-dropdown').toggleClass('open');
        } else {
            e.stopPropagation();
        }
    });

    $('#modern-local-navigation .hover-pill > a').click(
        function (e) {
            if (!($(e.target).is('.glyphicon'))) { //if click on glyphicon open drop-down

                e.stopPropagation();

                if($(this).attr('href')){
                    //close other pills/tabs in the same group
                    $(this).closest('.mega-menu-content-flex').find('.tab-pane.fade.active.in').removeClass('active in');
                    window.location = $(this).attr('href');
                }
            } 
        });
        
    function isTouchDevice(){
        return window.matchMedia('(pointer: coarse)').matches;
    }
    //activates hover-dropdown and hover-pill with hover
    function setHoverMenuActivation() {

        var hoverDropdownTimerHide;
        $('.hover-dropdown').hover(
            function () {
                if(isTouchDevice()) {
                    return;
                }
                clearTimeout(hoverDropdownTimerHide);
                //close all other hover-dropdown 
                $('.hover-dropdown').removeClass('open');
                $('.hover-dropdown').find('.dropdown-menu').removeClass('open');

                $(this).addClass('open');
                $(this).find('.dropdown-menu').addClass('open');
                if ($(".navbar").hasClass("modern")) {
                    $("#overlay").css("top", $(".navbar").height() + "px");
                    $("#overlay").show();
                }

            },
            function () {
                if(isTouchDevice()) {
                    return;
                }
                var dropdown = $(this).find('.dropdown-menu');
                var listItem = $(this);
                hoverDropdownTimerHide = setTimeout(function () {
                    listItem.removeClass('open');
                    dropdown.removeClass('open');

                    if ($(".navbar").hasClass("modern")) {
                        $("#overlay").hide();
                    }
                }, 300);

            });

        $('.hover-pill').hover(
            function () {
                if(isTouchDevice()) {
                    return;
                }
                //close other pills/tabs in the same group
                $(this).closest('.mega-menu-content-flex').find('.tab-pane.fade.active.in').removeClass('active in');

                //open the tab on hover
                $(this).find('.nav-link').tab('show');
            },
            function () {
                //nothing here, the last active pill remains active
            });

    }

    $(window).on('resize', function () {
        setTimeout(function () {
            autocollapse();
            //recalculate overlay offset
            $("#overlay").css("top", $(".navbar").height() + "px");
        }, 50);
    });

    $(".navbar-toggle").on("click", function () {
        $(this).toggleClass("active");
        $('body').toggleClass("noscroll");
    });

    $('.menu-flex').addClass('navigation-ready');

    autocollapse();

    var navbar = $('#local-navigation').length ? $('#local-navigation') : $('#modern-local-navigation');

    if (!navbar.hasClass('fixed-header')) {
        stickyHeader(navbar);
        $(window).on('scroll', function () {
            stickyHeader(navbar);
        });
    }

    /* 
     * Sticky sub navigation menu 
     */

    // Obtain the sub navigation menu links
    var $subNavMenu = $("#sub-navigation");
    var $menuLinks = $subNavMenu.find("a");

    // Given an anchor ID, return the anchor tag element
    var getAnchorFromHref = function (anchorId) {
        if (anchorId) {
            var $target = $("a[name='" + anchorId.substring(1) + "']");
            if ($target.length > 0) {
                return $target;
            }
        }
    };

    // If the page has a fixed main menu navbar, account for this height too
    var mainMenuHeight = function () {
        return (!navbar.hasClass('fixed-header')) ? navbar.outerHeight() : 0;
    };

    var isSubNavMenuFixed = function () {
        return $subNavMenu.hasClass("sub-navigation-container--fixed");
    };

    // Calculate the height of the main menu AND the sub navigation menu
    var mainMenuAndSubMenuHeight = function () {
        return mainMenuHeight() + $subNavMenu.outerHeight();
    };

    // If sub navigation links exist, then try and render the menu
    if ($menuLinks.length > 0) {

        // Get references for the Hero area
        var $heroArea = $(".jumbotron-container");

        // Attach a click handler to override the default behaviour and scroll instead
        $menuLinks.each(function (i, linkElement) {
            $(linkElement).on("click", function (e) {
                var anchorId = $(this).attr("href");
                var $target = getAnchorFromHref(anchorId);
                if ($target) {
                    e.preventDefault();
                    // Calculate distance from top of window (deducting both menu heights so it's not obscured)
                    var scrollTopPos = $target.offset().top - mainMenuAndSubMenuHeight();
                    // But if the sub nav menu isn't fixed yet (i.e. we're at the top of page), 
                    // then deduct the navbar height to account for this.
                    // Because it WILL be fixed by the time we've scrolled there!
                    if (!isSubNavMenuFixed()) {
                        scrollTopPos -= $subNavMenu.outerHeight();
                    }
                    $('html, body').animate({
                        scrollTop: scrollTopPos
                    }, 400);
                }
            });
        });

        // Render a sub navigation menu that "sticks" under the Hero area when scrolled out of view
        var renderStickySubHeader = function () {

            // Set the scroll top value
            var windowScrollTop = $(window).scrollTop();
            var menuDistanceFromTop = 0;

            // If a Hero area is present, use this to set the menu's distance from top of window
            if ($heroArea.length > 0) {
                var heroHeight = $heroArea.outerHeight();
                var heroOffsetTop = $heroArea.offset().top;
                menuDistanceFromTop = heroHeight + heroOffsetTop;
            }

            // If the page has a fixed main menu navbar, account for this height too
            menuDistanceFromTop -= mainMenuHeight();

            // If the window is scrolled past the menu's position, then make it "stick"
            if (windowScrollTop > menuDistanceFromTop) {
                $subNavMenu.addClass("sub-navigation-container--fixed");
            } else {
                $subNavMenu.removeClass("sub-navigation-container--fixed");
            }

            // Highlight which anchor is visible based on scroll position
            $menuLinks.each(function (i, v) {
                var anchorId = $(v).attr("href");
                var $target = getAnchorFromHref(anchorId);
                if ($target) {
                    // Get how far the target anchor element is from top of window
                    var targetTop = $target.offset().top;
                    // Account for main menu and sub menu heights
                    targetTop -= mainMenuAndSubMenuHeight();
                    // If the window is scrolled to the anchor, then add the active class.
                    // Sometimes the target's offset top is a decimal, use Math.floor to round down.
                    if (windowScrollTop >= Math.floor(targetTop)) {
                        $subNavMenu.find("a").removeClass("active");
                        $(this).addClass("active");
                    }
                }
            });
        };

        // Execute function on load
        renderStickySubHeader();

        // Create a "debounce" wrapper for the function to trottle the invocations.
        var debouncedFn = Utilities.debounce(renderStickySubHeader, 10);

        // Execute function on scroll
        $(window).on('scroll', function () {
            debouncedFn();
        });
    }
});

// TODO $.getScript() does not work with Jasmine tests in Chutzpah
//$.when($.getScript(EdqResourceVersion + "/js/jquery.menu-aim.min.js"))
//.done(function () {
$(document).ready(function () {
    $(".has-mega-menu .dropdown-menu, .mega-menu-dropdown .sub-menu").menuAim({
        activate: function (activeRow) {
            if (!isMobileMenu() && $(activeRow).hasClass("has-mega-menu-panel")) {
                $(activeRow).addClass("active").find("div").css('min-height', $(activeRow).parent().height()).addClass("open-menu").show().end().siblings().removeClass("active");
            }
        },
        deactivate: function (activeRow) {
            if (!isMobileMenu()) {
                $(activeRow).removeClass("active").find("div").removeClass("open-menu").hide();
            }
        },
        // exit the menu, only if we've actually left, and not gone back up to the main level item.
        exitMenu: function (d) {
            return ($(d).parent(".mega-menu-dropdown").hasClass("active")) ? false : true;
        }
    });
});


//});

$(".top-menu-primary, #menu-link-user-name").on({
    // Desktop
    mouseenter: function () {
        if (isMobileMenu()) {
            return;
        }

        var $topMenuItem = $(this);
        var $topMenuItemSubMenu = $(".sub-menu", $topMenuItem);
        $topMenuItem.attr("need-menu", "true");

        // If we have a mega-menu to show, open up the first list by default.
        if ($topMenuItemSubMenu.parent().hasClass("mega-menu-dropdown")) {
            $topMenuItemSubMenu.find("div").removeClass("open-menu").hide().end().find("li:first").addClass("active").find("div").height($topMenuItemSubMenu.height()).addClass("open-menu").show().on("mouseleave", function () {
                $(this).addClass("active").find("div").removeClass("open-menu").hide();
            });
        }

        // Slide up other menus
        $topMenuItem.siblings("[data-state='open']").attr("data-state", "closed").removeClass("active").find(".sub-menu").slideUp();

        $topMenuItem.addClass("active");
        // Check to show the sub-menu (i.e. don't show if they very briefly hover over item when moving mouse)
        checkToShowSubMenu($topMenuItem, $topMenuItemSubMenu);
    },
    mouseleave: function () {
        if (isMobileMenu()) {
            return;
        }

        var $topMenuItem = $(this);
        var $topMenuItemSubMenu = $(".sub-menu", $topMenuItem);
        $topMenuItem.removeAttr("need-menu");
        $topMenuItem.removeClass("active").find("li").removeClass("active");
        var speed = ($topMenuItemSubMenu.parent().hasClass("mega-menu-dropdown")) ? 0 : 150;
        $topMenuItemSubMenu.slideUp(speed, function () {
            $topMenuItem.attr("data-state", "closed");
        });
    },
    // Tablet/mobile
    click: function (event) {
        if (!isMobileMenu()) {
            return;
        }
        var $topMenuItem = $(this);
        var $topMenuItemSubMenu = $(".sub-menu", $topMenuItem);
        var state = $topMenuItem.attr("data-state");

        // Slide up other menus
        $topMenuItem.siblings("[data-state='open']").attr("data-state", "closed").removeClass("active").find(".sub-menu").hide(function () {
            $(this).siblings(".mobile-submenu-toggle").toggleChevron();
        });

        if (state === "open") {
            $(".mobile-submenu-toggle", $topMenuItem).toggleChevron();
            $topMenuItemSubMenu.slideUp(function () {
                $topMenuItem.attr("data-state", "closed");
                $topMenuItem.removeClass("active");
            });
        }
        else {
            $topMenuItem.addClass("active");
            $(".mobile-submenu-toggle", $topMenuItem).toggleChevron();
            $topMenuItemSubMenu.slideDown(function () {
                $topMenuItem.attr("data-state", "open");
            });
        }
    }
},
    "> li:not(.top-menu-secondary)");

$(".top-menu-primary").on("click", "> li:not(.top-menu-secondary) a", function (event) {
    event.stopPropagation();
});

/* Replace normal link functionality with script-based approach on mobile
   Used to prevent the need to double-click links in Safari on iDevices (and possibly other cases) */

// Trigger the replacement when the mobile menu is opened for the first time
$(".navbar-toggle").one("click", function () {
    var menuLinks = {
        topLevel: "ul.top-menu-primary > li > a[href]", // Top level links
        subLevel: "ul.top-menu-primary > li > ul.sub-menu > li > a[href]" // Sub-menu
    };

    $(menuLinks.topLevel + "," + menuLinks.subLevel).each(function () {
        $(this).on("touchstart", function (e) {
            e.preventDefault();
            window.location = this.href;
        });
    });
});

var isMobileMenu = function () {
    return $(".navbar-toggle").is(":visible") && $(".navbar-top-four").is(":hidden");
};

// Timer which shows sub menu when needed
// This is needed to run on a timer in case we enter the top menu accidentally (too briefly)
var checkToShowSubMenu = function (topMenuItem, subMenu) {
    setTimeout(function () {
        if (topMenuItem.attr("need-menu")) {
            // slide down if not already
            subMenu.slideDown(0, function () {
                topMenuItem.attr("data-state", "open");
            });
        }
    }, 100);
};

// quick plugin to toggle the chevron icons on mobile view
$.fn.toggleChevron = function () {
    if ($(this).hasClass("icon-down-open")) {
        $(this).removeClass("icon-down-open").addClass("icon-up-open");
    } else {
        $(this).removeClass("icon-up-open").addClass("icon-down-open");
    }
};

/* SEARCH BOX TOGGLE */

// Add placeholder and search icon highlight when ready to use
var $searchContainers = $(".search-list-item, .search-block-container"),
    searchQuery,
    $submitButton = $("<button/>", { "type": "submit", "class": "search-button search-submit-button" })
                    .append($("<span>", { "class": "icon-search" })),

    activateSearch = function ($containers) {
        $containers.each(function(){
            var $container = $(this);
            var $searchField = $container.find("input[type='text']");

            if ($searchField.length > 0) {
                setPlaceholder($container, $searchField);
                appendCustomSearchButton($container, $searchField);
            }
            else {
                // The text input for this container hasn't been injected by GCSE so try again
                setTimeout(function () {
                    activateSearch($container);
                }, 100);
            }
        });
    },
    setPlaceholder = function($container, $searchField){
        $searchField.attr("placeholder", $container.data("placeholder")).attr("type", "search");
    },
    appendCustomSearchButton = function($container, $searchField){
        $container.find(".form-search").append($submitButton);

        $submitButton.on("click", function () {
            if ($searchField.val() !== "") {
                $("input.gsc-search-button").click();
            }
        });
    };
activateSearch($searchContainers);

$("[data-toggle='search']").on("click", function (e) {

    // Get key form elements
    var $target = $(this),
        $searchContainer        = $(".search-list-item"),
        $searchField            = $searchContainer.find("input[type='search']"),
        $searchForm             = $searchContainer.find(".form-search"),
        $searchIcon             = $searchContainer.find("button[data-toggle='search']").find("span"),
        animationDelay          = 150,
        searchQuery,
        focusTimeout,

        // Figure out how far to extend to the right
        //openWidth               = ($containingMenu.offset().left + $containingMenu.outerWidth() - $searchField.offset().left) + 8;
        openWidth = 50;

    // If the room to the right is too small, open to the left instead
    if (openWidth < 60) {
        openWidth = 190;
        $searchForm.addClass("open-left");
    }

    // Prevent this click bubbling up to the body event handler
    e.stopPropagation();

    // Prevent the input from flashing if it's quickly reopened after closing
    clearTimeout(focusTimeout);

    $searchField.on("focus", function () {
        // Make sure the cursor is always at the end of the input text
        searchQuery = $(this).val(); // store the value
        setTimeout(function () {
            $(this).val(""); // clear the input
            $(this).val(searchQuery); // restore the value
        }, 0);
        // Switch the search icon
        setTimeout(function () {
            $searchIcon.removeClass("icon-search").addClass("icon-cancel");
        }, animationDelay);
    });

    // Collapse the search panel when the user clicks away
    $searchField.on("blur", function () {
        focusTimeout = setTimeout(function () {
            $searchForm.css("width", 0);
            $searchContainer.removeClass("open");
            $searchIcon.parent().blur(); // Prevent button being higlighted
            // Switch the search icon
            setTimeout(function () {
                $searchIcon.removeClass("icon-cancel").addClass("icon-search");
            }, animationDelay);
        }, animationDelay);
    });

    // Add a class and open up the search panel
    if (!$searchContainer.hasClass("open")) {
        $searchContainer.addClass("open");
        $searchForm.css("width", openWidth);
        setTimeout(function () {
            $searchField.focus();
        }, 0);
    }
        // Clear the search text if the cancel icon is clicked
    else if ($target.is("button")) {
        setTimeout(function () {
            $searchField.val("");
        }, animationDelay);
    }
});


/* SEARCH RESULTS */

if (location.pathname.toLowerCase().indexOf("/search-results/") !== -1) {

    var $globalSearchContainer = $(".top-menu .search-list-item"),
        $searchResultsContainer = $("#search-results-container"),
        $searchResultsLoader = $("#search-results-loader"),
        $searchResultsHeader = $("#search-results-header"),
        searchAgainBoxQuery = "#search-results-container .gsc-search-box .gsc-input input",

        searchLayout = {

            set: function () {

                if ($(searchAgainBoxQuery).length > 0) {

                    // Move search input next to search header and add a submit button
                    $(searchAgainBoxQuery).first().attr({
                        "placeholder": $globalSearchContainer.data("placeholder"),
                        "type": "search"
                    }).appendTo($searchResultsHeader)
                    .on("focus blur", function () {
                          $(this).parent().toggleClass("focus");
                    })
                    .on("keyup", function (e) {
                        if (e.which === 13) {
                            // Update the search box in the header
                            $globalSearchContainer.find("input[type='search']").val($(this).val());
                        }
                    })
                    .focus();

                    $submitButton.appendTo($searchResultsHeader).on("click", function () {
                        if ($(searchAgainBoxQuery).val() !== "") {
                            // Update the search box in the header
                            $globalSearchContainer.find("input[type='search']").val($(searchAgainBoxQuery).val());
                            // Trigger a click on the real GSC search button                            
                            $searchResultsContainer.find("input.gsc-search-button").click();
                        }
                        $(this).blur();
                    });
                  
                    // Remove the search loading spinner
                    $searchResultsLoader.parent().toggleLoaders("hide");
                }
                    // Attempt to set the search layout until the search results are shown
                else {
                    setTimeout(function () {
                        searchLayout.set();
                    }, 100);
                }

            }
        };

    // Attempt to set the search layout until the search results are shown
    searchLayout.set();
}
/*global console */

// Unregister the ServiceWorker
if (navigator.serviceWorker && typeof navigator.serviceWorker.getRegistrations === "function") {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (var i = 0; i < registrations.length; i++) {
            var registration = registrations[i];
            if (registration.unregister) {
                registration.unregister();
            }
        }
    });
}
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */
/* SHA-256 (FIPS 180-4) implementation in JavaScript                  (c) Chris Veness 2002-2017  */
/*                                                                                   MIT Licence  */
/* www.movable-type.co.uk/scripts/sha256.html                                                     */
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */


/**
 * SHA-256 hash function reference implementation.
 *
 * This is an annotated direct implementation of FIPS 180-4, without any optimisations. It is
 * intended to aid understanding of the algorithm rather than for production use.
 *
 * While it could be used where performance is not critical, I would recommend using the ‘Web
 * Cryptography API’ (developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest) for the browser,
 * or the ‘crypto’ library (nodejs.org/api/crypto.html#crypto_class_hash) in Node.js.
 *
 * See csrc.nist.gov/groups/ST/toolkit/secure_hashing.html
 *     csrc.nist.gov/groups/ST/toolkit/examples.html
 */
class Sha256 {

    /**
     * Generates SHA-256 hash of string.
     *
     * @param   {string} msg - (Unicode) string to be hashed.
     * @param   {Object} [options]
     * @param   {string} [options.msgFormat=string] - Message format: 'string' for JavaScript string
     *   (gets converted to UTF-8 for hashing); 'hex-bytes' for string of hex bytes ('616263' ≡ 'abc') .
     * @param   {string} [options.outFormat=hex] - Output format: 'hex' for string of contiguous
     *   hex bytes; 'hex-w' for grouping hex bytes into groups of (4 byte / 8 character) words.
     * @returns {string} Hash of msg as hex character string.
     */
    static hash(msg, options) {
        const defaults = { msgFormat: 'string', outFormat: 'hex' };
        const opt = $.extend({}, defaults, options);

        // note use throughout this routine of 'n >>> 0' to coerce Number 'n' to unsigned 32-bit integer

        switch (opt.msgFormat) {
            default: // default is to convert string to UTF-8, as SHA only deals with byte-streams
            case 'string':   msg = utf8Encode(msg);       break;
            case 'hex-bytes':msg = hexBytesToString(msg); break; // mostly for running tests
        }

        // constants [§4.2.2]
        const K = [
            0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5, 0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5,
            0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3, 0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174,
            0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc, 0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da,
            0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7, 0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967,
            0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13, 0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85,
            0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3, 0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070,
            0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5, 0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3,
            0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208, 0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2 ];

        // initial hash value [§5.3.3]
        const H = [
            0x6a09e667, 0xbb67ae85, 0x3c6ef372, 0xa54ff53a, 0x510e527f, 0x9b05688c, 0x1f83d9ab, 0x5be0cd19 ];

        // PREPROCESSING [§6.2.1]

        msg += String.fromCharCode(0x80);  // add trailing '1' bit (+ 0's padding) to string [§5.1.1]

        // convert string msg into 512-bit blocks (array of 16 32-bit integers) [§5.2.1]
        const l = msg.length/4 + 2; // length (in 32-bit integers) of msg + ‘1’ + appended length
        const N = Math.ceil(l/16);  // number of 16-integer (512-bit) blocks required to hold 'l' ints
        const M = new Array(N);     // message M is N×16 array of 32-bit integers

        for (let i=0; i<N; i++) {
            M[i] = new Array(16);
            for (let j=0; j<16; j++) { // encode 4 chars per integer (64 per block), big-endian encoding
                M[i][j] = (msg.charCodeAt(i*64+j*4+0)<<24) | (msg.charCodeAt(i*64+j*4+1)<<16)
                        | (msg.charCodeAt(i*64+j*4+2)<< 8) | (msg.charCodeAt(i*64+j*4+3)<< 0);
            } // note running off the end of msg is ok 'cos bitwise ops on NaN return 0
        }
        // add length (in bits) into final pair of 32-bit integers (big-endian) [§5.1.1]
        // note: most significant word would be (len-1)*8 >>> 32, but since JS converts
        // bitwise-op args to 32 bits, we need to simulate this by arithmetic operators
        const lenHi = ((msg.length-1)*8) / Math.pow(2, 32);
        const lenLo = ((msg.length-1)*8) >>> 0;
        M[N-1][14] = Math.floor(lenHi);
        M[N-1][15] = lenLo;


        // HASH COMPUTATION [§6.2.2]

        for (let i=0; i<N; i++) {
            const W = new Array(64);

            // 1 - prepare message schedule 'W'
            for (let t=0;  t<16; t++) {
                W[t] = M[i][t];
            }
            for (let t=16; t<64; t++) {
                W[t] = (Sha256.σ1(W[t-2]) + W[t-7] + Sha256.σ0(W[t-15]) + W[t-16]) >>> 0;
            }

            // 2 - initialise working variables a, b, c, d, e, f, g, h with previous hash value
            let a = H[0], b = H[1], c = H[2], d = H[3], e = H[4], f = H[5], g = H[6], h = H[7];

            // 3 - main loop (note '>>> 0' for 'addition modulo 2^32')
            for (let t=0; t<64; t++) {
                const T1 = h + Sha256.Σ1(e) + Sha256.Ch(e, f, g) + K[t] + W[t];
                const T2 =     Sha256.Σ0(a) + Sha256.Maj(a, b, c);
                h = g;
                g = f;
                f = e;
                e = (d + T1) >>> 0;
                d = c;
                c = b;
                b = a;
                a = (T1 + T2) >>> 0;
            }

            // 4 - compute the new intermediate hash value (note '>>> 0' for 'addition modulo 2^32')
            H[0] = (H[0]+a) >>> 0;
            H[1] = (H[1]+b) >>> 0;
            H[2] = (H[2]+c) >>> 0;
            H[3] = (H[3]+d) >>> 0;
            H[4] = (H[4]+e) >>> 0;
            H[5] = (H[5]+f) >>> 0;
            H[6] = (H[6]+g) >>> 0;
            H[7] = (H[7]+h) >>> 0;
        }

        // convert H0..H7 to hex strings (with leading zeros)
        for (let h=0; h<H.length; h++) {
            H[h] = ('00000000'+H[h].toString(16)).slice(-8);
        }

        // concatenate H0..H7, with separator if required
        const separator = opt.outFormat === 'hex-w' ? ' ' : '';

        return H.join(separator);

        /* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

        function utf8Encode(str) {
            try {
                return new TextEncoder().encode(str, 'utf-8').reduce((prev, curr) => prev + String.fromCharCode(curr), '');
            } catch (e) { // no TextEncoder available?
                return window.unescape(encodeURIComponent(str)); // monsur.hossa.in/2012/07/20/utf-8-in-javascript.html
            }
        }

        function hexBytesToString(hexStr) { // convert string of hex numbers to a string of chars (eg '616263' -> 'abc').
            const str = hexStr.replace(' ', ''); // allow space-separated groups
            return str === '' ? '' : str.match(/.{2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
        }
    }



    /**
     * Rotates right (circular right shift) value x by n positions [§3.2.4].
     * @private
     */
    static ROTR(n, x) {
        return (x >>> n) | (x << (32-n));
    }


    /**
     * Logical functions [§4.1.2].
     * @private
     */
    static Σ0(x) { return Sha256.ROTR(2,  x) ^ Sha256.ROTR(13, x) ^ Sha256.ROTR(22, x); }
    static Σ1(x) { return Sha256.ROTR(6,  x) ^ Sha256.ROTR(11, x) ^ Sha256.ROTR(25, x); }
    static σ0(x) { return Sha256.ROTR(7,  x) ^ Sha256.ROTR(18, x) ^ (x>>>3);  }
    static σ1(x) { return Sha256.ROTR(17, x) ^ Sha256.ROTR(19, x) ^ (x>>>10); }
    static Ch(x, y, z)  { return (x & y) ^ (~x & z); }          // 'choice'
    static Maj(x, y, z) { return (x & y) ^ (x & z) ^ (y & z); } // 'majority'

}


/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -  */

if (typeof window.module !== 'undefined' && window.module.exports) {
    window.module.exports = Sha256; // ≡ export default Sha256
}

window.Sha256 = Sha256;

/* SITE SELECTOR */

var siteSelector = {

    $element: $("body > .container.site-selector"),

    $link: $("li.global-sites"),

    state: null,

    states: {
        open: "open",
        closed: "closed"
    },

    open: function () {
        siteSelector.$link.addClass("active");
        siteSelector.$element.slideDown(function () {
            siteSelector.state = siteSelector.states.open;
            $(this).attr("data-state", siteSelector.state);
        });
    },

    close: function () {
        siteSelector.$element.slideUp(function () {
            siteSelector.$link.removeClass("active");
            siteSelector.state = siteSelector.states.closed;
            $(this).attr("data-state", siteSelector.state);
        });
    }
};

siteSelector.state = siteSelector.$element.attr("data-state");

var bindGlobalSitesClick = function (event) {

    event.preventDefault();

    if (siteSelector.state === siteSelector.states.closed) {
        siteSelector.open();
    }
    else {
        siteSelector.close();
    }
};

$(document.body).on("click", "[data-toggle='global-sites']", bindGlobalSitesClick);

$("body > div").not(siteSelector.$element).on("click", function () {
    if ($(window).width() > Utilities.breakpoint.xs) {
        siteSelector.close();
    }
});
/*global require */

require(['libraries/tablesaw.jquery'], function (Tablesaw) {
    Tablesaw.init();
    $(document.body).trigger( "enhance.tablesaw" );
});
});
/*global _elqQ, _elqSiteId, _elqSiteName, _elqLookupId, _elqLookupUrl, EdqResourceVersion,
  GetElqCustomerGUID,WaitUntilCustomerGUIDIsRetrieved, GetElqContentPersonalizationValue,
  adWordsConversionLabel, Sha256 */
/*jshint sub:true */   // Suppresses warnings about [] notation when it can be expressed in dot notation

var EdqXforms = EdqXforms || {};

EdqXforms.init = function () {

    // ELOQUA
    // Integrate Eloqua into any forms on this page
    EdqXforms.eloqua.init();

    // CUSTOM FIELDS
    // Replace certain form fields with custom versions (eg dropdowns, radios, checkboxes)
    EdqXforms.injectCustomFields();

    // VALIDATION
    // Set up fields for validation and run initial validation on Eoqua-populated fields
    EdqXforms.validation.init();

    // XFORM POST
    // Set up xform submit
    EdqXforms.xformPost.init();

};

EdqXforms.eloqua = {
    $form: null,
    eloquaFormName: "",
    $eloquaCoreFields: null,
    $eloquaProfilingFields: null,

    eloquaFieldNames: {
        firstName: "C_FirstName",
        lastName: "C_LastName",
        email: "C_EmailAddress",
        company: "C_Company",
        phone: "C_BusPhone",
        jobTitle: "C_Job_Title1",
        jobRole: "C_Job_Role1",
        jobLevel: "C_Job_Level_Normalized1",
        state: "C_State_Prov",
        country: "C_Country",
        industry: "C_Industry1",
        companySize: "C_Company_Size1",
        topicOfInterest: "C_Topic_of_Interest1"
    },
    // Map XForm field names with Eloqua field names
    getFieldMappings: function(){
        return [
            {
                "FirstName": EdqXforms.eloqua.eloquaFieldNames.firstName
            },
            {
                "First_Name": EdqXforms.eloqua.eloquaFieldNames.firstName
            },
            {
                "CustomerFirstName": EdqXforms.eloqua.eloquaFieldNames.firstName
            },
            {
                "Forename": EdqXforms.eloqua.eloquaFieldNames.firstName
            },
            {
                "Surname": EdqXforms.eloqua.eloquaFieldNames.lastName
            },
            {
                "LastName": EdqXforms.eloqua.eloquaFieldNames.lastName
            },
            {
                "Last_Name": EdqXforms.eloqua.eloquaFieldNames.lastName
            },
            {
                "CustomerLastName": EdqXforms.eloqua.eloquaFieldNames.lastName
            },
            {
                "JobTitle": EdqXforms.eloqua.eloquaFieldNames.jobTitle
            },
            {
                "Organisation": EdqXforms.eloqua.eloquaFieldNames.company
            },
            {
                "Organization": EdqXforms.eloqua.eloquaFieldNames.company
            },
            {
                "Company": EdqXforms.eloqua.eloquaFieldNames.company
            },
            {
                "CompanyName": EdqXforms.eloqua.eloquaFieldNames.company
            },
            {
                "Email": EdqXforms.eloqua.eloquaFieldNames.email
            },
            {
                "EmailAddress": EdqXforms.eloqua.eloquaFieldNames.email
            },
            {
                "Phone": EdqXforms.eloqua.eloquaFieldNames.phone
            },
            {
                "Telephone": EdqXforms.eloqua.eloquaFieldNames.phone
            },
            {
                "State": EdqXforms.eloqua.eloquaFieldNames.state
            },
            {
                "Country": EdqXforms.eloqua.eloquaFieldNames.country
            },
            {
                "JobRole": EdqXforms.eloqua.eloquaFieldNames.jobRole
            },
            {
                "JobLevel": EdqXforms.eloqua.eloquaFieldNames.jobLevel
            },
            {
                "jobLevelNormalized1": EdqXforms.eloqua.eloquaFieldNames.jobLevel
            },
            {
                "Industry": EdqXforms.eloqua.eloquaFieldNames.industry
            },
            {
                "EmployeeCount": EdqXforms.eloqua.eloquaFieldNames.companySize
            },
            {
                "DataQualityChallenge": EdqXforms.eloqua.eloquaFieldNames.topicOfInterest
            },
            {
                "TopicOfInterest": EdqXforms.eloqua.eloquaFieldNames.topicOfInterest
            },
            {
                "Topic_of_Interest": EdqXforms.eloqua.eloquaFieldNames.topicOfInterest
            }
        ];
    },

    injectHiddenFields: function () {
        EdqXforms.eloqua.$form.each(function () {
            var hiddenFields = "<input type=\"hidden\" name=\"elqFormName\" value=\"" + EdqXforms.eloqua.eloquaFormName + "\">";
            hiddenFields += "<input type=\"hidden\" name=\"elqSiteID\" value=\"" + _elqSiteId + "\">";
            hiddenFields += "<input type=\"hidden\" name=\"elqCustomerGUID\">";
            hiddenFields += "<input type=\"hidden\" name=\"elqCookieWrite\" value=\"0\">";
            hiddenFields += "<input type=\"hidden\" name=\"debugElq\" value=\"true\">";
            hiddenFields += "<input type=\"hidden\" name=\"associatedContent\">";
            $(this).addClass("eloqua-enabled").prepend(hiddenFields);
        });
    },

    visitorLookup: function () {
        // Do the first lookup - The Eloqua Visitor Data Lookup from the Cookie.
        _elqQ.push(['elqDataLookup', encodeURI('c94994dd77fb48e198e5a8ba295b2280'), '']);

        // Add the GUID from the cookie to the form
        var timerId = null, timeout = 5, firstLookup = true;
        window.WaitUntilCustomerGUIDIsRetrieved = function () {
            if (!!(timerId)) {
                if (timeout === 0) {
                    return;
                }
                if (typeof this.GetElqCustomerGUID === 'function' && $('[name="elqCustomerGUID"]').length > 0) {
                    EdqXforms.eloqua.$form.find("[name='elqCustomerGUID']").val(GetElqCustomerGUID());
                    return;
                }
                timeout -= 1;
            }
            timerId = setTimeout(WaitUntilCustomerGUIDIsRetrieved, 500);
            return;
        };
        $(window).on("load", WaitUntilCustomerGUIDIsRetrieved);

        // SetElqContent is triggered by the Data Lookup Scripts automatically.
        window.SetElqContent = function () {
            // If we have done the first lookup
            if (firstLookup) {
                // Do the second lookup - The Eloqua Contact Data Lookup from the
                // Email Address we obtained from the first lookup
                // We use the email address because in our case it uniquely
                // identifies a contact
                var visitorEmail = GetElqContentPersonalizationValue('V_ElqEmailAddress');
                if (!Utilities.isNullOrEmpty(visitorEmail)) {                
                    _elqQ.push(['elqDataLookup', encodeURI(_elqLookupId),
                                '<C_EmailAddress>' + visitorEmail + '</C_EmailAddress>']);
                    firstLookup = false;
                } else {
                    // The visitor email is empty, so the 2nd lookup won't complete and "SetElqContent" won't get called again.
                    // In this case, trigger the postEloquaDataLookup event here.
                    $("body").trigger("postEloquaDataLookup");
                }
            }
                // This else block code is run after the second lookup completes since
                // it triggers another call to SetElqContent() and firstLookup will be
                // false
            else {
                if(EdqXforms.eloqua.$form.length > 0) {
                    EdqXforms.eloqua.$form.each(function () {
                        if (!$(this).hasClass("pre-pop-disabled")) {
                            EdqXforms.eloqua.prepopulateFormFields($(this));
                        }
                    });
                }

                // Trigger a custom event so other parts of the code can listen for when the data lookup has completed
                $("body").trigger("postEloquaDataLookup");
            }
        };
    },

    populateFormField: function (field, fieldValue) {
        var prepopped = false;
        if (field.is("select")) {
            // If value is found in options list
            var foundOption = false;
            field.find("option").each(function (i, element) {
                if (fieldValue === $(element).val()) {
                    foundOption = true;
                    // break the each loop
                    return false;
                }
            });
            if (foundOption) {
                field.val(fieldValue);
                prepopped = true;
            }
        } else {
            field.val(fieldValue);
            prepopped = true;
        }
        return prepopped;
    },

    prepopulateFormFields: function ($form) {
        // Pre-populate the form
        if (window.GetElqContentPersonalizationValue) {
            var prePopped = false;

            // Loop over and populate the form fields
            var fieldMappings = EdqXforms.eloqua.getFieldMappings();
            for (var i = 0; i < fieldMappings.length; i++) {
                for (var fieldName in fieldMappings[i]) {
                    if (fieldMappings[i].hasOwnProperty(fieldName)) {
                        var eloquaName = fieldMappings[i][fieldName];
                        var field = $form.find("div[content-name='" + fieldName + "'] input");
                        var fieldValue = GetElqContentPersonalizationValue(eloquaName);
                        if (fieldValue && field.length > 0) {
                            prePopped = EdqXforms.eloqua.populateFormField(field, fieldValue);
                        }
                    }
                }
            }

            // If any are pre-popped show them a clear form link
            if (prePopped) {
                EdqXforms.eloqua.insertClearFormLink();
            }

            // If ALL of the core Eloqua fields have been pre-populated, start progressive profiling
            if (EdqXforms.eloqua.coreFieldsPrepopped()) {
                EdqXforms.eloqua.progressiveProfiling();
            }
        }
    },

    progressiveProfiling: function () {
        // Hide the core fields
        EdqXforms.eloqua.$eloquaCoreFields.closest("div").addClass("hide");
        // Start exposing/revealing the profiling fields
        var $nextField = EdqXforms.eloqua.getNextProfilingField();
        // Show this field
        if ($nextField) {
            $nextField.closest("div").removeClass("hide");
        }
    },

    insertClearFormLink: function () {

        // Don't create duplicate if already exists
        if (EdqXforms.eloqua.$form.find(".clear-form").length > 0) {
            return;
        }
        if (EdqXforms.eloqua.$form.closest('.modern') && EdqXforms.eloqua.$form.closest(".xform-content").find(".clear-form").length > 0){
            return;
        }
        // Calculate how wide to make the alert
        var $clearFormDiv = $("<div/>", {
            "class": "clear-form alert alert-warning small",
            "text": EdqXforms.i18n.translations["form"]["notYou"]
        }), $clearFormLink = $("<a/>", {
            "text": EdqXforms.i18n.translations["form"]["clearForm"],
            "href": "#"
        }).on("click", function (e) {

            e.preventDefault();
            // clear cookie as well?
            //document.createCookie(cookieName, null, 1);

            //in case more than one form is present, clear only the form where the clear fields was called
            var $target = $(e.target);
            var $relevantFormContainer = $target.closest('.edq-xform');
            var $relevantForm;

            if($relevantFormContainer.length){
                $relevantForm = $relevantFormContainer.find('form');
            }
            else{
                $relevantForm = EdqXforms.eloqua.$form;
            }
            // clear form fields
            $relevantForm[0].reset();

            // reset custom dropdowns
            $relevantForm.find("select").change();

            // reset custom radios and checkboxes
            $relevantForm.find("label[data-type='radio'], label[data-type='checkbox']")
                .removeClass("active")
                .each(function () {
                    if ($(this).find("input").attr("checked") === "checked") {
                        $(this).addClass("active");
                    }
                });

            // remove validation markers                    
            EdqXforms.validation.setFieldState.clear(
                $relevantForm.find("input, select, textarea")
            );

            // cancel ongoing validation
            if (EdqXforms.validation.realTime.inProgress.email !== null) {
                EdqXforms.validation.realTime.inProgress.email.abort();
            }
            if (EdqXforms.validation.realTime.inProgress.phoneNumber !== null) {
                EdqXforms.validation.realTime.inProgress.phoneNumber.abort();
            }
            $(".loader-formfield").remove();

            // Show any core Eloqua fields and hide the profiling ones
            EdqXforms.eloqua.$eloquaCoreFields.closest("div").removeClass("hide");
            EdqXforms.eloqua.$eloquaProfilingFields.closest("div").addClass("hide");

            // hide alert
            $(this).parent()
                .css({
                    "border-color": "transparent"
                })
                .slideUp(function () {
                    $(this).remove();
                });
        });

        //if modern theme is applied: add clear form above the form container
        if(!(EdqXforms.eloqua.$form.closest('.modern').length)){

            EdqXforms.eloqua.$form.prepend($clearFormDiv.append($clearFormLink));
        }
        else{
            EdqXforms.eloqua.$form.closest(".xform-content").prepend($clearFormDiv.append($clearFormLink));
        }
        
    },

    coreFieldsPrepopped: function () {
        var areFieldsPrepopped = true;
        EdqXforms.eloqua.$eloquaCoreFields.each(function (index, field) {
            // If any are empty then they're not all pre-populated
            if ($(field).val() === "") {
                areFieldsPrepopped = false;
                return false;
            }
        });
        return areFieldsPrepopped;
    },

    getNextProfilingField: function () {
        var $nextField = "";
        EdqXforms.eloqua.$eloquaProfilingFields.each(function (index, field) {
            // Get the first empty field
            if (!$(field).val()) {
                $nextField = $(field);
                return false;
            }
        });
        return $nextField;
    },

    init: function () {
        EdqXforms.eloqua.$form = $(".xform").not(".eloqua-enabled");

        // If the _elqQ var exists, do some Eloqua processing
        if (typeof (_elqQ) !== "undefined") {
            // If we have any Xforms on the page, integrate Eloqua
            if (EdqXforms.eloqua.$form.length > 0) {
                // Ascertain what types of fields are Eloqua core or profiling ones
                EdqXforms.eloqua.$eloquaCoreFields = EdqXforms.eloqua.$form.find(".elq-core-field");
                EdqXforms.eloqua.$eloquaProfilingFields = EdqXforms.eloqua.$form.find(".elq-profiling-field");
                // By default hide the profiling fields until we need them
                EdqXforms.eloqua.$eloquaProfilingFields.closest("div").addClass("hide");

                // Grab the Eloqua form name to use from the Xform's data attribute
                EdqXforms.eloqua.eloquaFormName = EdqXforms.eloqua.$form.attr("data-eloquaformname");

                // For each Xform add the hidden fields necessary for Eloqua
                EdqXforms.eloqua.injectHiddenFields();
            }

            // Perform a visitor lookup and pre-populate the form fields (if applicable)
            EdqXforms.eloqua.visitorLookup();    
        }    
    }

};

EdqXforms.injectCustomFields = function () {
    // Get all inputs and their labels
    // multiInputs are sets of radios and checkboxes
    var $multiInputs = $(".edq-xform input[type='radio'], input[type='checkbox']"),
        $multiLabels = $multiInputs.closest("label"),
        $singleInputs = $(".edq-xform").find("input, select, textarea")
                                        .not("[type='radio'], [type='checkbox'], [type='button'], [type='submit']"),
        $selects = $("select"),
        $input, $label, $groupLabels,

        // Function to close dropdowns on certain keypresses
        closeDropdowns = function ($form) {
            $form.find(".dropdown.open").removeClass("open");
        },

        // Function for removing validation errors
        removeValidationError = function ($field) {
            $field.closest("td, div").find(".field-validation-error")
                                .fadeOut("fast", function () {
                                    $(this).remove();
                                });
            $field.closest("td, div").removeClass("invalid");
        };

    // Global function for creating custom dropdowns
    // Global status lets it be used dynamically and not just on page load
    window.createDropdown = function ($dropdown, textOverride, overrideAttributeName) {
        // ignore country autocomplete dropdowns
        if ($dropdown.hasClass("country-list-autocomplete")) {
            return;
        }
        // Remove pre-existing dropdown
        $dropdown.siblings("div.dropdown").not(".persistent").remove();
        // Create the Bootstrap dropdown components
        var $div, $button, $text, $caret, $ul, $li, $a,
            dropdownVal = {
                originalVal: "", newVal: ""
            },
            dropdownChange, dropdownWatcher, $newItem;
        $div = $("<div/>", {
            "class": "dropdown"
        });
        $button = $("<button/>", {
            "id": "control-for-" + $dropdown.attr("id"), "name": "control-for-" + $dropdown.attr("name"), "title": $dropdown.attr("title"),
            "class": "btn dropdown-toggle", "type": "button", "data-toggle": "dropdown",
            "aria-expanded": "true"
        });
        $text = $("<span/>", {
            "class": "value"
        });
        $caret = $("<span/>", {
            "class": "arrow icon-down-open"
        });
        $ul = $("<ul/>", {
            "class": "dropdown-menu", "role": "menu", "aria-labelledby": $dropdown.attr("id")
        });

        // Carry through selected classes from the original select box
        ["white", "no-visible-validation", "show-text-on-error", "country-list", "phone-v3"].forEach(function (s) {
            if ($dropdown.hasClass(s)) {
                $div.addClass(s);
            }
        });

        // Create an <li> and <a> for each option in the original <select> box
        $dropdown.find("option").each(function (index) {
            var isSelected = $(this).attr("selected"),
                iconClass = "",
                $flagImg;

            // Create an icon if the option has the icon attribute
            if (typeof $(this).attr("icon") !== "undefined") {
                iconClass += " icon-" + $(this).attr("icon");
            }
            // Create a flag if the option has the data-iso attribute and the select has the country-list class
            if (typeof $(this).data("iso") !== "undefined" && $dropdown.hasClass("country-list")) {
                var iso2 = $(this).data("iso"),
                    src = EdqResourceVersion + "/images/flags/" + iso2.toLowerCase() + ".svg",
                    name = $(this).text();
                $flagImg = Utilities.lazyImage(src, name, { "class": "inline-flag", "data-country": iso2 });
            }

            /* For select boxes which are not required fields,
                * ignore elements with no value (things like "Please select" won't appear in list) */
            if ($(this).attr("value") !== "" || !$dropdown.hasClass("required")) {
                var optionClass = (isSelected) ? "selected" : "";
                $li = $("<li/>", {
                    "role": "presentation"
                });
                $a = $("<a/>", {
                    "role": "menuitem", "tabindex": "-1",
                    "value": $(this).attr("value"),
                    "text-value": $(this).text(),
                    "text": $(this).text(),
                    "class": optionClass + iconClass
                });
                // Nest the option elements
                if ($flagImg !== null) {
                    $a.prepend($flagImg);
                }
                $li.append($a);
                $ul.append($li);
            }
            // Set the dropdown's inital text
            if (index === 0 || isSelected) {
                if (textOverride === true) {
                    $text.html($(this).attr(overrideAttributeName));
                }
                else {
                    $text.html($(this).html());
                }
            }
        });
        // Nest the dropdown elements and insert in page
        $button.append($text, $caret);
        $div.append($button);
        $div.append($ul);
        $dropdown.after($div);
        $div.on("click focus", function () { $(this).removeClass("no-visible-validation"); });

        // Bind an event listener to the original dropdown, and set an interval to listen
        // for changes in the original dropdown
        // Causes the new dropdown to update if the user or Eloqua updates the original select box
        dropdownVal.originalVal = $dropdown.val();
        dropdownChange = function () {
            dropdownVal.newVal = $dropdown.val();
            if (dropdownVal.newVal !== null) {
                $newItem = $ul.find("a[value='" + dropdownVal.newVal + "']");
                $ul.find("a").removeClass("selected");
                $text.attr({ "class": $newItem.attr("class") });
                $newItem.addClass("selected");
                
                var displayText = $newItem.html();
                
                if (textOverride === true && displayText !== null) {
                    var selectedItem = $dropdown.find("option:selected");
                    if (selectedItem !== null && typeof selectedItem.data("iso") !== "undefined") {
                        displayText = $newItem.find("img").get(0).outerHTML + selectedItem.attr(overrideAttributeName);
                    }
                }
                
                $text.html(displayText);

                if ($text.html() === "" || $text.html() === null) {
                    if (textOverride === true) {
                        $text.html($dropdown.find("option").first().attr(overrideAttributeName));
                    }
                    else {
                        $text.html($dropdown.find("option").first().html());
                    }
                }
            }
        };
        dropdownWatcher = setInterval(function () {
            dropdownVal.newVal = $dropdown.val();
            if (dropdownVal.originalVal !== dropdownVal.newVal) {
                dropdownChange();
                clearInterval(dropdownWatcher);
            }
        }, 250);
        $dropdown.on("change", dropdownChange);

        // Bind an event listener to all dropdown items
        // Causes the item to be selected in the new and original dropdown
        $ul.find("a").on("click", function (e) {
            e.preventDefault();
            // Update original select box
            $dropdown.val($(this).attr("value")).change();
            // Update new dropdown
            $ul.find("a").removeClass("selected");
            $(this).addClass("selected");
            var displayText = $(this).html();
            
            if (textOverride === true) {
                var selectedItem = $dropdown.find("option:selected");
                if (selectedItem !== null && typeof selectedItem.data("iso") !== "undefined") {
                    displayText = $(this).find("img").get(0).outerHTML + $dropdown.find("option:selected").attr(overrideAttributeName);
                }
            }

            $text.html(displayText);
            $button.focus();
            // Remove any validation errors
            removeValidationError($(this));
        });

        // Bind an event listener to any forms which have dropdowns
        // Causes dropdowns to close when Tab is pressed (fixes accessibility bug)
        $dropdown.closest("form").on("keyup", function (e) {
            if (e.which === 9) { // Tab
                closeDropdowns($(this));
            }
        });

        // Bind an event listener to all dropdowns (fires when they open)
        // Removes the invalid class (red borders)
        $div.on("show.bs.dropdown", function () {
            $(this).closest("td, div").removeClass("invalid");
        });

        // Hide the original select box
        $dropdown.attr("tabindex", "-1");
        $dropdown.addClass("replaced");

        // Trigger the change event on the original dropdown to allow for
        // dynamic selection that might otherwise be missed
        $dropdown.change();
    };

    // Add a data attr to multiInput labels to identify them as radio labels or checkbox labels
    // Add the active class to labels whose input is selected by default
    $multiLabels.each(function () {
        $label = $(this);
        $input = $(this).find("input").first();

        $label.attr("data-type", $input.attr("type"));
        if ($input.filter(":checked").length > 0) {
            $label.addClass("active");
        }
    });

    // Add a data attr to select labels to identify them as such
    $selects.each(function () {
        $(this).siblings("label").attr("data-type", "select");
    });

    // Turn all dropdowns into custom versions
    $selects.each(function () {
        window.createDropdown($(this));
    });

    // When a radio or checkbox state changes, add/remove the active and invalid classes from labels as appropriate
    $multiInputs.on("change", function () {
        $groupLabels = $(this).closest("fieldset").find("label");
        if ($(this).closest("label").attr("data-type") === "radio") {
            $groupLabels.removeClass("active");
        }
        $(this).closest("label").toggleClass("active").removeClass("invalid, focus");
        $groupLabels.removeClass("invalid");
        removeValidationError($(this));
    });

    // Apply focus states to radios and checkboxes
    $multiInputs.on("focus", function () {
        $(this).closest("label").addClass("focus");
    });
    $multiInputs.on("blur", function () {
        $(this).closest("label").removeClass("focus");
    });

    // When single-input states change, remove the invalid classes from them
    $singleInputs.on("focus change keyup input paste", function () {
        removeValidationError($(this));
    });

    $singleInputs.on("click, focus", function () {
        $(this).removeClass("no-visible-validation");
    });
    
    // Change type attribute to 'tel' for phone number fields and prepend +1 label
    $(".edq-xform input.validate-phone").attr('type', 'tel').after('<div class="phone-prepend">+1</div>');

};

EdqXforms.validation = {

    fieldsToProcess: "input.required:visible, select.required:visible, textarea.required:visible, fieldset.required:visible",
    fieldsToExclude: "[type='radio'], [type='checkbox']", // Exclude these as the fieldset is tested once as a whole
    allFields: "input, select, textarea",
    requiredMarker: " <span class='text-red'>*</span>",
    submitQueuedMarker: "submit-queued",
    validateAddressClass: "validate-address",
    validateEmailClass: "validate-email",
    validatePhoneClass: "validate-phone",
    validatePasswordClass: "validate-password",
    validateRealTimeClass: "validate-realtime",
    emailRegex: /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i,

    init: function () {

        // IE8 - get around validation bugs by overwriting the email and phone validations
        // with the standard field ones
        if ($("html").first().hasClass("lt-ie9")) {
            EdqXforms.validation.validateEmail = EdqXforms.validation.validatePhone = EdqXforms.validation.validateField;
        }

        // Show asterisk next to fields requiring validation
        $(".xform").find(EdqXforms.validation.fieldsToProcess).not(EdqXforms.validation.fieldsToExclude).each(function () {
            if ($(this).hasClass("required")) {
                $(this).prev("label").not(".has-validation-marker").append(EdqXforms.validation.requiredMarker).addClass("has-validation-marker");
                $(this).find("legend").not(":empty").not(".has-validation-marker").append(EdqXforms.validation.requiredMarker).addClass("has-validation-marker");
            }
        });

        // Make form field label bold when "bold" class has been manually added in the xform editor
        $(".xform").find(EdqXforms.validation.allFields).each(function () {
            if($(this).hasClass("text-bold")) {
                $(this).prev("label").not(".text-bold").addClass("text-bold");
                $(this).removeClass("text-bold");
            }
        });


        // Validate fields which are auto-populated by Eloqua
        $(window).on("load", function () {
            $(".edq-xform").find("input, textarea, select").trigger("initialValidation");
        });

        // Validate normal fields which are entered by the user
        $(EdqXforms.validation.fieldsToProcess, ".xform")
            .not(EdqXforms.validation.fieldsToExclude +
                    ", input." + EdqXforms.validation.validateAddressClass +
                    ", input." + EdqXforms.validation.validateEmailClass +
                    ", input." + EdqXforms.validation.validatePasswordClass +
                    ", input." + EdqXforms.validation.validatePhoneClass)
            .each(function () {
                $(this).on("blur change initialValidation", function (e) {
                    EdqXforms.validation.validateField($(this), e);
                });
            });

        // Validate an address has been entered by the user
        $(".edq-xform input." + EdqXforms.validation.validateAddressClass).each(function () {
            $(this).on("blur change initialValidation", function (e) {
                // Use a timeout to prevent running this if the form has been submitted by the user
                var $field = $(this);
                setTimeout(function () {
                    EdqXforms.validation.validateAddress($field, e);
                }, 0);
            });
        });

        // Validate emails which are entered by the user
        $(".edq-xform input." + EdqXforms.validation.validateEmailClass).each(function () {
            $(this).on("blur change initialValidation", function (e) {
                // Use a timeout to prevent running this if the form has been submitted by the user
                var $field = $(this);
                setTimeout(function () {
                    EdqXforms.validation.validateEmail($field, e);
                }, 0);
            });
        });

        // Validate phone numbers which are entered by the user
        $(".edq-xform input." + EdqXforms.validation.validatePhoneClass).each(function () {
            $(this).on("blur change initialValidation", function (e) {
                // Use a timeout to prevent running this if the form has been submitted by the user
                var $field = $(this);
                setTimeout(function () {
                    EdqXforms.validation.validatePhone($field, e);
                }, 0);
            });
        });

        // When changes are made, cancel queued submission and remove "valid" markers
        $(".xform").find("input, textarea, label, legend").on("click", function () {
            $(this).closest(".xform").removeAttr(EdqXforms.validation.submitQueuedMarker);
        });
    },

    validateField: function ($field, e) {
        var valid = false,
        regex = /\S/,
        message = ($field.is("fieldset, select")) ? "<span>" + EdqXforms.i18n.translations["form"]["makeSelection"] + "</span>" :
                                                    "<span>" + EdqXforms.i18n.translations["form"]["fillIn"] + " " + EdqXforms.i18n.translations["form"]["thisInformation"] + "</span>";

        // Field contains multiple inputs (must be a fieldset with radios or checkboxes)
        if ($field.find("input").length > 0) {
            if ($field.find("input:checked").length > 0) {
                valid = true;
            }
        }
            // Field does not contain multiple inputs (must be a normal input, textarea or select)
        else if (regex.test($field.val())) {
            valid = true;
        }

        if (valid) {
            EdqXforms.validation.setFieldState.valid($field, null);
        }
        else if (e.type !== "initialValidation") {
            EdqXforms.validation.setFieldState.invalid($field, message);
            e.preventDefault();
        }
    },

    validateAddress: function ($field, e, calledFromPageLoad) {
        //setState.error(settings.messages.error);
        var settings = {
            messages: {
                success: EdqXforms.i18n.translations["form"]["address"]["success"],
                error: EdqXforms.i18n.translations["form"]["address"]["error"],
                regexFail: EdqXforms.i18n.translations["form"]["address"]["regexFail"]
            },
            regex: /^.*$/
        };

        EdqXforms.validation.realTime.check($field, e, calledFromPageLoad, settings);

    },

    validateEmail: function ($field, e, calledFromPageLoad) {

        var settings = {
            fieldType: EdqXforms.validation.realTime.types.email,
            ajaxEndpoint: "/ajaxutilities/isemailaddressvalid",
            messages: {
                success: EdqXforms.i18n.translations["form"]["email"]["success"],
                error: EdqXforms.i18n.translations["form"]["email"]["error"],
                regexFail: EdqXforms.i18n.translations["form"]["email"]["regexFail"],
                domainRegexFail: EdqXforms.i18n.translations["form"]["email"]["domainRegexFail"]
            },
            tracking: {
                eventName: "RealTimeEmailValidate",
                varName: "emailValidateResult"
            },
            regex: EdqXforms.validation.emailRegex,
            domainRegex: /\@(?!(me|mac|icloud|gmail|googlemail|hotmail|live|msn|outlook|yahoo|aol|mail|company-email)\.)/i
        };

        EdqXforms.validation.realTime.check($field, e, calledFromPageLoad, settings);

    },

    validatePhone: function ($field, e, calledFromPageLoad) {

        // Run phone validation after getting geolocation
        Utilities.geolocation.runAfter(function () {
            var settings = {
                fieldType: EdqXforms.validation.realTime.types.phoneNumber,
                isoCode: "US", // currently fixed to US/Canada only (+1 prefix)
                ajaxEndpoint: "/ajaxutilities/isphonenumbervalid",
                messages: {
                    success: EdqXforms.i18n.translations["form"]["phone"]["success"],
                    error: EdqXforms.i18n.translations["form"]["phone"]["error"],
                    regexFail: EdqXforms.i18n.translations["form"]["phone"]["regexFail"]
                },
                tracking: {
                    eventName: "RealTimePhoneValidate",
                    varName: "phoneValidateResult"
                },
                regex: /[0-9]{1,6}/
            };

            EdqXforms.validation.realTime.check($field, e, calledFromPageLoad, settings);
        });
    },

    realTime: {

        types: {
            email: "email",
            phoneNumber: "phoneNumber"
        },

        inProgress: {
            email: null,
            phoneNumber: null
        },

        check: function ($field, e, calledFromPageLoad, settings) {

            var run = function () {

                if (Utilities.isNullOrEmpty(settings.ajaxEndpoint)) {
                    return;
                }

                // Set up vars for the data to send and tracking variables to push
                var ajaxData = {},
                trackingData = {
                    "event": settings.tracking.eventName
                };
                ajaxData[settings.fieldType] = $field.val();
                if (settings.isoCode !== null) {
                    ajaxData.isoCode = settings.isoCode;
                }

                // Mark this field as undergoing validation
                EdqXforms.validation.realTime.showLoader($field);

                // Scroll any validating fields into view
                if (!calledFromPageLoad && $('.xform').closest(".xform-modal-container").length < 1) {
                    EdqXforms.validation.scrollTo($field.closest("form"), "[validating]");
                }

                // Validate the inputted data
                if (EdqXforms.validation.realTime.inProgress[settings.fieldType] !== null) {
                    EdqXforms.validation.realTime.inProgress[settings.fieldType].abort();
                }
                EdqXforms.validation.realTime.inProgress[settings.fieldType] = $.ajax({
                    data: ajaxData,
                    dataType: "json",
                    timeout: 5000,
                    type: "POST",
                    url: settings.ajaxEndpoint,
                    success: function (result) {
                        if (result.isValid) {
                            setState.success(settings.messages.success);
                            trackingData[settings.tracking.varName] = "Valid";
                            Utilities.trackEvent(trackingData);
                        }
                        else {
                            setState.error(settings.messages.error);
                            trackingData[settings.tracking.varName] = "Invalid";
                            Utilities.trackEvent(trackingData);
                        }
                    },
                    error: function (jqXHR) {
                        // Silently fail, and mark the input as valid
                        if (jqXHR.statusText !== "abort") {
                            setState.success(settings.messages.success);
                            trackingData[settings.tracking.varName] = "Error";
                            Utilities.trackEvent(trackingData);
                        }
                    }
                });
            },

            setState = {
                complete: function () {
                    // Remove loading spinner from the field
                    $field.closest("td, div").find(".loader").remove();
                    // Remove validating marker
                    $field.removeAttr("validating");
                },
                error: function (message) {
                    setState.complete();
                    EdqXforms.validation.setFieldState.invalid($field, message, false);
                },
                reset: function () {
                    $field.closest("td, div").find(".loader").remove();
                    $field.closest("td, div").find(".field-validation-success, .field-validation-error").remove();
                    // Store the value of the field to prevent validating the same value over and over
                    $field.attr("val", $field.val());
                },
                success: function (message) {
                    setState.complete();
                    // Don't trigger "valid" for address capture field, just because it's been typed in
                    // "valid" is only triggered after a formatted address
                    if (!$field.hasClass(EdqXforms.validation.validateAddressClass)) {
                        EdqXforms.validation.setFieldState.valid($field, message, true);
                    }

                    if (typeof $field.closest("form").attr(EdqXforms.validation.submitQueuedMarker) !== "undefined") {
                        $field.closest("form").submit();
                    }
                }
            },

            syntax = {
                fail: function (message) {
                    EdqXforms.validation.setFieldState.invalid($field, message, true);
                    $field.closest("form").removeAttr(EdqXforms.validation.submitQueuedMarker);
                    setState.complete();
                    $field.removeAttr("invalid, persistent, val");

                    if (!Utilities.isNullOrEmpty(EdqXforms.validation.realTime.inProgress[settings.fieldType])) {
                        EdqXforms.validation.realTime.inProgress[settings.fieldType].abort();
                    }
                },
                regex: settings.regex,
                domainRegex: settings.domainRegex
            };

            // Prevent form being submitted if there are validation errors,
            // or if we don't yet know whether the inputted data is valid
            if (($field.closest("form").find("[invalid]").length > 0 || typeof $field.attr("valid") === "undefined") &&
                typeof e !== "undefined" && e !== null) {
                e.preventDefault();
            }

            // Validate inputted data using basic syntax check
            if ($field.hasClass("required") && $field.val() === "") {
                if (e && e.type !== "initialValidation") {
                    syntax.fail("<span>" + EdqXforms.i18n.translations["form"]["fillIn"] + " " + EdqXforms.i18n.translations["form"]["thisInformation"] + "</span>");
                }
            }
            else if (!syntax.regex.test($field.val())) {
                syntax.fail(settings.messages.regexFail);
            }
            // Validate inputted data against invalid domains - only applies to email
            else if (settings.fieldType === EdqXforms.validation.realTime.types.email && !syntax.domainRegex.test($field.val())) {
                syntax.fail(settings.messages.domainRegexFail);
            }
            // If syntax is fine, validate inputted data using the appropriate real time validation
            else if ($field.val() !== "" && typeof $field.val() !== "undefined" && $field.val() !== $field.attr("val")) {
                setState.reset();
                if ($field.closest(".edq-xform").hasClass(EdqXforms.validation.validateRealTimeClass)) {
                    run();
                }
                else {
                    setState.success("");
                }
            }

        },

        showLoader: function ($field) {
            // Mark field as validating
            $field.attr("validating", true);
            // Add loading spinner inside the input field
            if ($field.closest("td, div").find(".loader").length < 1) {
                $field.closest("td, div").find("label, legend").first()
                    .append($("<div/>", {
                        "class": "loader loader-inline loader-formfield"
                    }).append($("<div/>", {
                        "class": "spinner"
                    }))
                );
            }
        }

    },

    runValidation: function ($form, e) {
        // Remove previous validation markers, except those marked as persistent)
        $form.find(".field-validation-error, .field-validation-success").not("[persistent]").remove();
        $form.find("[invalid], .invalid").not("[persistent]").removeAttr("invalid").removeClass("invalid");

        // For each form field that qualifies for validation, run the appropriate validation methods
        $(EdqXforms.validation.fieldsToProcess, $form).not(EdqXforms.validation.fieldsToExclude).each(function () {
            var $field = $(this);

            if ($field.hasClass(EdqXforms.validation.validateEmailClass)) {
                EdqXforms.validation.validateEmail($field, e);
            }

            else if ($field.hasClass(EdqXforms.validation.validatePhoneClass)) {
                EdqXforms.validation.validatePhone($field, e);
            }

            else if ($field.hasClass("required")) {
                EdqXforms.validation.validateField($field, e);
            }
        });

        // If there are errors, scroll the first one into view
        EdqXforms.validation.scrollTo($form, ".invalid, [invalid], .field-validation-error");
    },

    setFieldState: {
        valid: function ($field, message, isPersistentMessage) {
            $field.removeAttr("invalid persistent unknown").attr("valid", "true");
            $field.siblings(".dropdown").find("button").attr("valid", "true");
            $field.closest("td, div").removeClass("invalid").removeAttr("persistent");
            $field.closest("td, div").find(".field-validation-warning, .field-validation-error").remove();
            if (typeof message !== "undefined" && $field.closest("td, div").find(".field-validation-success").length < 1) {
                var $titleElement = $field.closest("td, div").find("label, legend").first();

                if (typeof $titleElement[0] !== "undefined" && $titleElement[0].localName !== "legend")
                {
                    $titleElement.append($("<span/>", {
                        "class": "field-validation-success icon-ok",
                        "title": message,
                        "persistent": isPersistentMessage
                    }));
                }

                if (isPersistentMessage) {
                    $field.attr("persistent", isPersistentMessage);
                    $field.closest("td, div").attr("persistent", isPersistentMessage);
                }
            }
            $field.trigger("valid");
        },

        unknown: function ($field, message, isPersistentMessage) {
            $field.removeAttr("invalid valid persistent").attr("unknown", "true");
            $field.closest("td, div").removeClass("invalid").removeAttr("persistent");
            $field.closest("td, div").find(".field-validation-success, .field-validation-error").remove();
            if (typeof message !== "undefined" && $field.closest("td, div").find(".field-validation-warning").length < 1) {
                $field.closest("td, div").find("label, legend").first()
                .append($("<span/>", {
                    "class": "field-validation-warning icon-attention",
                    "title": message,
                    "persistent": isPersistentMessage
                }));
                if (isPersistentMessage) {
                    $field.attr("persistent", isPersistentMessage);
                    $field.closest("td, div").attr("persistent", isPersistentMessage);
                }
            }
            //$field.trigger("unknown");
        },

        invalid: function ($field, message, isPersistentMessage) {
            $field.attr("invalid", "true").closest("td, div").addClass("invalid");
            $field.removeAttr("valid unknown");
            $field.closest("td, div").find(".field-validation-success, .field-validation-warning").remove();
            if (isPersistentMessage) {
                $field.attr("persistent", isPersistentMessage);
                $field.closest("td, div").attr("persistent", isPersistentMessage);
            }
            EdqXforms.validation.addValidationMessage($field, message, isPersistentMessage);
            $field.trigger("invalid");
        },

        clear: function ($field) {
            $field.removeAttr("invalid valid unknown")
                    .siblings().find("*").removeAttr("invalid valid unknown").removeClass("invalid valid unknown");
            $field.closest("td, div").removeClass("invalid valid unknown").find(".field-validation-success, .field-validation-warning, .field-validation-error").remove();
        }
    },

    scrollTo: function ($form, selector) {
        // If there are any validating fields, scroll the first one into view
        if ($form.find(selector).length > 0 && !$form.attr("scrolling")) {
            $form.attr("scrolling", true);
            var firstValidationY = $form.find(selector).closest("td, div")
                                        .find("label, legend").first().offset().top;
            if (firstValidationY < $(document).scrollTop()) {
                $("html, body").animate({
                    "scrollTop": firstValidationY
                }, "slow",
                    function () {
                    $form.removeAttr("scrolling");
                }
                );
            }
            else {
                $form.removeAttr("scrolling");
            }
        }
    },

    addValidationMessage: function ($field, message, isPersistentMessage) {
        function unspan(string) {
            return string.split("<span>").join("").split("&nbsp;").join("").split("</span>").join("");
        }

        if ($field.closest("td, div").find(".field-validation-error").length < 1) {
            var hasLegend = $field.closest("td, div").find("legend").length > 0;
            var positioning = hasLegend ? "after" : "append";
            var checkboxClass = hasLegend ? " field-validation-error-checkbox" : "";
            var $validationMessage = $("<span/>", {
                "class": "field-validation-error" + checkboxClass,
                "title": unspan(message),
                "persistent": isPersistentMessage,
                html: message
            });
            var $validationIcon = $("<span/>", {
                "class": "field-validation-error icon-attention" + checkboxClass,
                "title": unspan(message),
                "persistent": isPersistentMessage
            });
            var firstElement = hasLegend ? $validationIcon : $validationMessage;
            var secondElement = hasLegend ? $validationMessage : $validationIcon;

            $field.closest("td, div").find("label, legend").first()
                [positioning](firstElement)
                [positioning](secondElement);
                $field.closest("td, div").find("label .field-validation-error").on("click", function () {
                $(this).closest("td, div").find("input, select, textarea").first().focus();
            });
        }
    }
};

EdqXforms.xformPost = {

    init: function () {
        $(".xform").each(function () {
            var $xform = $(this),
                $submitButton = $xform.find("button[type=submit]");
            if (typeof $submitButton[0] !== "undefined" && typeof $submitButton[0].name !== "undefined") {
                $submitButton.addClass("btn");
                $xform.on("submit", function (e) {
                    e.preventDefault();
                    $xform.attr(EdqXforms.validation.submitQueuedMarker, true);
                    $submitButton.attr("disabled", "disabled");
                    EdqXforms.validation.runValidation($xform, e);
                    // Scroll to any validating or invalid inputs
                    if($xform.closest(".xform-modal-container").length < 1) {
                        EdqXforms.validation.scrollTo($xform, "[validating], .invalid, .field-validation-error");
                    }
                    if ($xform.find(".invalid, .field-validation-error").length === 0) {
                        EdqXforms.xformPost.start($xform.attr("id"));
                    } else {
                        $submitButton.removeAttr("disabled");
                        $xform.trigger("submit-failed-validation");
                    }
                });
            }
        });
    },

    start: function (formId) {
        var $form = $("#" + formId),
            $submitButton;

        if ($form !== "undefined" && $form !== null) {

            $form.removeAttr(EdqXforms.validation.submitQueuedMarker);

            $submitButton = $form.find("button[type=submit]");
            $submitButton.closest(".edq-xform").find(".xform-failed").addClass("hidden");

            var submitData = $form.serialize();
            if (typeof $submitButton[0] !== "undefined" && typeof $submitButton[0].name !== "undefined") {
                submitData += "&ConfirmationUrl=" + $submitButton.closest("div.edq-xform").find(".xform-success").data("confirmation-url");
                $("form div.Form__Element").each(function () {
                    var fieldName = $(this).attr("content-name");
                    var elementName = $(this).attr("data-f-element-name");
                    submitData += "&" + elementName + "_Name" + "=" + fieldName;
                });

                // html escape form data so we can safely accept characters such as <, >, etc.
                submitData = Utilities.htmlEscapeFormData(submitData);

                $.ajax({
                    type: "POST",
                    url: $form.attr("action"),
                    data: submitData,
                    success: function (result) { EdqXforms.xformPost.success(result, $submitButton, $form); },
                    error: function (xhr, status, error) {
                        if (status === "timeout") {
                            window.console.log("TIMEOUT");
                        }
                        EdqXforms.xformPost.failed($submitButton);
                    },
                    timeout: 15000
                });
            } else {
                EdqXforms.xformPost.failed($submitButton);
            }

        } else {
            EdqXforms.xformPost.failed($submitButton);
        }
    },

    success: function (result, $submitButton, $form) {
        if (result !== null && result.success !== null) {

            // Note: If a new GTM dataLayer event is added in this function then it should
            // also be added to the ajax success return in the function oneTimeClean.submit
            // in one-time-clean.js

            // Push GTM dataLayer event for email address hash
            if(!Utilities.isNullOrEmpty(Sha256)) {
                // How to reliably get the Email field??
                var emailValue = $(".edq-xform input." + EdqXforms.validation.validateEmailClass).val();
                if(Utilities.isNullOrEmpty(emailValue)){
                    $("form div.Form__Element").each(function () {
                        var fieldName = $(this).attr("content-name");
                        if (fieldName.toLowerCase().indexOf("email") >= 0) {
                            var inputValue = $(this).find("input").val();
                            if (EdqXforms.validation.emailRegex.test(inputValue)) {
                                emailValue = emailValue;
                                return false;
                            }
                        }
                    });
                }
                var emailHash = Sha256.hash(emailValue);
                
                Utilities.trackEvent({
                    "event": "UserId",
                    "userId": emailHash
                });
            }

            // Push GTM dataLayer event for company name
            if (typeof GetElqCustomerGUID === "function") {
                // Get the Eloqua ID and Company name
                var eloquaId = GetElqCustomerGUID();

                // How to reliably get the Company field?
                var companyName = $(".edq-xform input[name='Company']").val();
                if (Utilities.isNullOrEmpty(companyName)) {
                    companyName = $(".edq-xform input[name='Organisation']").val();
                } 
				
				if (Utilities.isNullOrEmpty(companyName)) {
                    companyName = $(".edq-xform input[name='Organization']").val();
                }

                // Create a custom event
                var obj = {
                    'event': 'EloquaLookup',
                    'customerGuid': eloquaId,
                    'companyName': companyName,
                };

                // Push event to the dataLayer        
                Utilities.trackEvent(obj);
            }

            // Push GTM dataLayer event for form submission
            var dataLayerObject = { event: "FormSubmission" },
                dataLayerVars = ["adWordsConversionLabel", "googleAnalyticsGoalType"];

            var isModalForm = $form.closest(".xform-modal-container").length > 0;

            $.each(dataLayerVars, function (i) {
                if (window[dataLayerVars[i]] !== "" && window[dataLayerVars[i]] !== null) {
                    dataLayerObject[dataLayerVars[i]] = window[dataLayerVars[i]];
                }
            });

            if (result.success === true) {

                $form.trigger("submit-success");

                if (!Utilities.isNullOrEmpty(result.redirectUrl)) {
                    dataLayerObject.eventCallback = function () {
                        window.location.href = $.encoder.encodeForHTML(result.redirectUrl);
                    };
                }
            }

            Utilities.trackEvent(dataLayerObject);

            if (result.success === true && Utilities.isNullOrEmpty(result.redirectUrl)) {

                var $formContent = $submitButton.closest("div.xform-content"),
                    $formContainer = $submitButton.closest(".container"),
                    $successMessage = $submitButton.closest("div.edq-xform").find(".xform-success"),
                    $gatedVideoContainer = $submitButton.closest(".resource-page").find(".gated-video-container");

                $formContent.addClass("hidden");
                $successMessage.removeClass("hidden");

                if ($gatedVideoContainer.length) {
                    $gatedVideoContainer.removeClass("hidden");
                }
                
                if (!isModalForm && $formContainer.offset().top < $(document).scrollTop()) {
                    $("html, body").animate({ "scrollTop": $formContainer.offset().top }, "slow");
                }
            }
            else if (result.success === false) {
                EdqXforms.xformPost.failed($submitButton);
            }
            return;
        }

        EdqXforms.xformPost.failed($submitButton);
    },

    failed: function ($submitButton) {
        $submitButton.closest(".edq-xform").find(".xform-failed").removeClass("hidden");
        $submitButton.removeAttr("disabled");
    }
};

EdqXforms.initModalForm = function (formId, pageId) {

    // Concatenate the pageId and formId so we can store whether this page has been "unlocked" by the current user
    var modalFormKey = pageId ? [pageId, formId].join("-") : formId;
    var unlockKeys = JSON.parse(Utilities.localStorage.getItem("unlockKeys")) || [];
    var $modalElementsCollection = $(".xform-modal-container, .xform-modal-backdrop");
    
    // If the page has not been unlocked, show the modal form and create event listeners
    if (unlockKeys.indexOf(modalFormKey) < 0 && $modalElementsCollection.length > 0) {
        // Depending on the XForms contextual usage. 
        var $modalElements = $(".xform-modal-container, .xform-modal-backdrop").filter("[data-form-id='" + formId + "']");
        $modalElements = $modalElements.length > 0 ? $modalElements : $modalElementsCollection.has("[data-form-id='" + formId + "']");
        $modalElements = $modalElements.add($modalElements.siblings('.xform-modal-backdrop'));
        var $modalForm = $modalElements.find("form");

        // Prevent page scrolling
        $("body").css("overflow", "hidden");
        $("body").css("position", "fixed");
        $("body").css("width", "100%");

        // Transition-in the modal and backdrop
        $modalElements.removeClass("hidden");
        setTimeout(function () {
            $modalElements.addClass("in");
        }, 100);

        // Upon successful submission
        $modalForm.on("submit-success", function () {
            $('body').trigger('xform.submit-success');
            
            // Add a key to the unlockKeys localStorage array to let the current user bypass the modal in future, DO NOT do this for modals on resource pages.
            if($modalElementsCollection.parents('.resource-page').length < 1) {
                unlockKeys.push(modalFormKey);
            }
            
            Utilities.localStorage.setItem("unlockKeys", JSON.stringify(unlockKeys));
        });
        
    }
};
// closes all open modal forms
EdqXforms.closeModalForms = function () {
    // Allow page scrolling
    $("body").css("overflow", "auto");
    $("body").css("position", "static");
    
    // Transition-out the modal and backdrop
    $(".xform-modal-container, .xform-modal-backdrop").removeClass("in");
};

$(".xform-modal-container, .close-modal-forms").on("click", function (event) {
    if ($(event.target).is(".xform-modal-container, .close-modal-forms")) {
        EdqXforms.closeModalForms();
    }
});

$(function () {
    // Initialise all forms on the current page
    EdqXforms.init();
});

// Disable forms in editor mode
$(function () {
    if ($("body").hasClass("editor-mode")) {
        $(".edq-xform").find("button[type='submit']").attr({
            "disabled": "disabled", "title": "Forms disabled in editor mode"
        });
    }
});